/*=========================================================================================
  Description: Category Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_CATEGORIES( state, categories ){
    state.categories = categories;
  },

}
