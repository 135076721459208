/*=========================================================================================
  File Name: moduleCustomers.js
  Description: Customers Module
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/


import state from './moduleCustomersState.js';
import mutations from './moduleCustomersMutations.js';
import actions from './moduleCustomersActions.js';
import getters from './moduleCustomersGetters.js';

export default {
	namespaced: true,
    state,
    mutations,
    actions,
    getters
}
