/*=========================================================================================
  File Name: moduleIndicators.js
  Description: Indicators Module
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/


import state from './moduleIndicatorsState.js';
import mutations from './moduleIndicatorsMutations.js';
import actions from './moduleIndicatorsActions.js';
import getters from './moduleIndicatorsGetters.js';

export default {
	namespaced: true,
    state,
    mutations,
    actions,
    getters
}
