/*=========================================================================================
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import auth from "@/auth/authService";

import Cookies  from 'js-cookie'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  isUserLoggedIn: () => {
    let isAuthenticated = false
    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser
    if (auth.isAuthenticated() && firebaseCurrentUser) isAuthenticated = true
    else isAuthenticated = false
    return (Cookies.get('userInfo') && isAuthenticated)
  },
}
