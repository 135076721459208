import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from '../assets/i18n/es.json'
import pt from '../assets/i18n/pt.json'
import en from '../assets/i18n/en.json'

Vue.use(VueI18n);
const messages = {
    'pt': pt,
    'es': es,
    'en': en
};

const i18n = new VueI18n({
    locale: 'pt', // set locale
    fallbackLocale: 'es', // set fallback locale
    messages, // set locale messages
});

export default i18n
