/*=========================================================================================
  Description: Stores Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_STORES( state, stores ){
    state.stores = stores;
  },

  SET_STORE( state, store ){
    state.store = store;
  },

  SET_STORE_CATEGORIES( state, cats ) {
    state.categories = cats;
  },

  CLEAR_STORE_DATA( state ) {
    state.store = {
      name             : '',
      code             : '',
      address          : '',
      city             : '',
      influence        : null,
      cx               : -58.1843,
      cy               : -15.6248,
      coords           : '-15.6248,-58.1843',
      delivery_price   : 0,
      phone            : '',
      type_shops_id    : 1,
      city_dane        : '',
      minimum_purchase : null,
    };
  },

}
