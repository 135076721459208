/*=========================================================================================
  File Name: moduleIndicatorsActions.js
  Description: Indicators Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import http from '../../http/http-client.js'

export default {

  getAllIndicators( {commit} ) {
    return http.get( 'api/indicators' )
      .then( res => {
        if( res ) commit( 'SET_INDICATORS', res.data );
        return res;
      });
  },

  getQuestionReports( { state, commit } ) {
    const range = state.dateRanges.find( range => range.id === state.rangeDateSelected );
    const value = range.value;

    return http.post( 'api/questionReports' , {
      option : "FEEDBACK",
      days   : value,
      
    } )
      .then( res => {
        if( res ) commit( 'SET_QUESION_REPORTS', res.data );
        return res;
      });
  },

  getShopsReports( { state, commit } ) {
    const range = state.dateRanges.find( range => range.id === state.rangeDateSelected );
    const value = range.value;

    return http.post( 'api/questionReports' , {
      option : "SHOPS",
      days   : value,
      
    } )
      .then( res => {
        if( res ) commit( 'SET_SHOPS_REPORTS', res.data );
        return res;
      });
  },
}
