/*=========================================================================================
  File Name: moduleCustomersMutations.js
  Description: Customers Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_CUSTOMER_SERVICE_LINES( state, lines ) {
    state.customerServiceLines = lines;
  },
  
}
