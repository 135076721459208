/*=========================================================================================
  File Name: moduleProductState.js
  Description: Product Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  products        : [],
  leads           : [],
  fileList        : [],
  product         : {
    title           : '',
    applications_id : 0,
    category_id     : 0,
    summary         : '',
    state           : 1,
    content         : '',
    med_form        : 1,
    sku             : '',
    image_url       : '',
    status_id       : 1,
    type_product    : 1,
    email_list      : "",
    email_list_arr  : [],
  },
  defaultImageUrl : '',
  lead : {
    city           : '',
    closing_date   : null,
    code           : '',
    contact_date   : null,
    contract_price : null,
    email          : '',
    full_name      : '',
    id             : 0,
    identification : '',
    phone          : '',
    send_date      : '',
    state          : null,
    status         : 0,
    user_id        : 0,
  }
}
