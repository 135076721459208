/*=========================================================================================
  File Name: moduleProductActions.js
  Description: Product Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import Cookies  from 'js-cookie';
import axios    from 'axios';
import firebase from 'firebase/app';
import 'firebase/storage';

import http  from '../../http/http-client.js';
const baseUrl = process.env.VUE_APP_BASE_LOAD;

const user = Cookies.get('userInfo') ? JSON.parse( Cookies.get('userInfo') ) : null;
let headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
}

export default {
  async getAllProducts (_, payload) {
    const u = new URLSearchParams(payload).toString();
    const res = await http.get( `api/products?${ u }` );
    if(!res) return;
    // commit( 'SET_PRODUCTS', res.data );
    return res.data;
  },

  massiveLoadOfProducts( {commit, dispatch}, files ) {
    files.forEach((file, i) => {

      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("app_id", user.company.app_id);
      
      axios({
        method: 'POST',
        data: formData,
        url: `${process.env.VUE_APP_BASE_URL}/api/uploadShops`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
        },
        onUploadProgress: function(progressEvent) {
          commit('SET_FILE_PROGRESS', {
            index: i,
            progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
          });
        }
      })
        .then( res => {
          console.log(res);
          // file.progress = 100;

        })
        .catch( (err) => {
          console.log(err);
          dispatch(
            'notification/setNotification', {
              title: `Error al cargar el archivo ${file.file.name}`,
              msg: '',
              icon: 'AlertOctagonIcon',
              time: new Date().getTime(),
              category: 'danger'
            },
            {root: true}
          );
          commit('SET_FILE_PROGRESS', {
            index: i,
            progress: 100,
            loadError: 'error garrafal',
          });
        });
      });
  
  },

  getProductBySKU( _, sku ) {
    return http.get( `api/productsfind/${sku}` ).then( res => res);
  },

  uploadProductImageToFirebase( _, image ) {
    const file = image.file;
    // obtener nombre y extension de archivo
    const filename = file.name;
    const ext = filename.substr(filename.lastIndexOf('.') + 1);
    // crear nombre basado en linea temporal referencia de firestorage
    const currentTimestamp = new Date().getTime();
    const route = `SAKURA/files/products/app_${ user.company.app_id }/images/${currentTimestamp}.${ext}`;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(route);
    // tarea de archivo
    return new Promise( ( resolve, reject ) => {
      let task = fileRef.put(file);
      // obtener progreso de subida de archivo
      task.on( 'state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        image.progress = percentage;
      });
      // archivo subido correctamente
      task.then( snapshot => {
        // obtener enlace de descarga
        snapshot.ref.getDownloadURL().then( (link) => {
          resolve(link)
        });

      });
      // error al subir el archivo
      task.catch( (err) => {
        // referenciar y mostrar en consola error de archivo
        console.log(err);
        image.error = 'Ocurrió un error al guardar el archivo. Intente de nuevo más tarde';
        reject( null );
      });
    });
  },

  saveProduct( {dispatch, commit}, payload ) {
    return http.post( 'api/products', payload )
      .then( res => {
        if( res ) {
          dispatch( 'getAllProducts' );
          commit( 'CLEAR_PRODUCT_DATA' );
        }
        return res;
      });
  },

  attempUploadFiles( {dispatch}, files ) {
    let xlsxFiles = [];
    let zipFiles = [];

    // Separar xlsx y Zip
    files.forEach( file => {
      const filename  = file.file.name;
      const ext = filename.substr(filename.lastIndexOf('.') + 1);

      if ( ext === 'xlsx' ) {
        xlsxFiles.push( file );
        
      } else if( ext === 'csv' ) {
        xlsxFiles.push( file );

      } else if( ext === 'zip' ) {
        zipFiles.push( file );
      }
    });
    // set files to firebase
    dispatch( 'uploadFilesFirebase', { files: xlsxFiles, type: 'xlsx'} );
    dispatch( 'uploadFilesFirebase', { files: zipFiles, type: 'zip'} );

  },

  uploadFilesFirebase( {dispatch}, data ) {
    let files = data.files;

    files.forEach( file => {
      // obtener nombre y extension de archivo
      const filename = file.file.name;
      const ext = filename.substr(filename.lastIndexOf('.') + 1);
      // crear nombre basado en linea temporal referencia de firestorage
      const currentTimestamp = new Date().getTime();
      const route = `files/products/app_${ user.company.app_id }/${currentTimestamp}.${ext}`;
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(route);
      // tarea de archivo
      let task = fileRef.put(file.file);
      // obtener progreso de subida de archivo
      task.on( 'state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        file.progress = percentage;
      });
      // archivo subido correctamente
      task.then( snapshot => {
        // obtener enlace de descarga
        snapshot.ref.getDownloadURL().then( (link) => {
          // objeto de datos para enviar archivo
          const newData = {
            firebaseUrl  : link,
            bucketPath   : `gs://${snapshot.ref.bucket}/${route}`,
            originalFile : filename,
            type         : data.type,
            extension    : ext
          }
          // enviar objeto de datos al servicio para posterior procesamiento
          dispatch( 'setProductFileToService', newData );

        });

      });
      // error al subir el archivo
      task.catch( (err) => {
        // referenciar y mostrar en consola error de archivo
        console.log(err);
        file.loadError = 'Ocurrió un error al guardar el archivo. Intente de nuevo más tarde';
      });

    });
  },

  setProductFileToService( {dispatch}, data ) {
    const payload = {
      firebase_url  : data.firebaseUrl,
      bucket_path   : data.bucketPath,
      original_file : data.originalFile,
      extension     : data.extension,
    }
    const url = data.extension === 'xlsx' || data.extension === 'csv' ? 'api/uploadProducts' : 'api/uploadImagesProducts';

    return http.post( url, payload )
      .then( res => {
        if( res ) {
          dispatch( 'getAllProducts' );
        }
        return res;
      });
  },

  deleteProduct( {dispatch}, productId ){
    http.delete( `api/products/${productId}` )
      .then( res => {
        if( res ) {
          dispatch( 'getAllProducts' );
        }
      })
  },

  uploadProduct( {dispatch}, product ) {
    return http.put( `api/products/${product.id}`, product )
      .then( res => {
        if( res ) {
          dispatch( 'getAllProducts' );
        }

        return res;
      });
  },

  getDefaultProductImage( {commit} ) {
    const storage = firebase.storage();
    let ref = storage.ref('images/default-product.png');

    return ref.getDownloadURL()
      .then( url => {
        commit( 'SET_DEFAULT_IMAGE_URL', url );
        return url;
      })
      .catch( err => {
        console.log(`An error ocurred trying get the default product Image ${err.message}`);
        return null;
      });
  },

  getProductsLeads( {dispatch}, id=0 ) {
    if( id === 0 ) return dispatch('getAllLeads');
    else return dispatch( 'getLeadById', id );
  },

  getAllLeads( {commit} ) {
    return http.get('api/leads')
      .then( res => {
        if( res ) commit('SET_LEADS', res.data );
        return res;
      });
  },

  getLeadById( {commit}, id ) {
    return http.get(`api/leads/${id}`)
      .then( res => {
        if( res ) commit('SET_LEAD', res.data );
        return res;
      });
  },

  saveLead( {dispatch}, payload ) {
    if( payload.id === 0 ) return dispatch('createLead', payload);
    else return dispatch('updateLead', payload);
  },

  createLead( {commit, dispatch}, payload) {
    return http.post('api/leads', payload)
      .then( res => {
        if( res ) {
          commit('CLEAR_LEAD_DATA');
          dispatch('getAllLeads');
        }
        return res;
      });
  },

  updateLead( {commit, dispatch}, payload ) {
    return http.put(`api/leads/${payload.id}`, payload)
      .then( res => {
        if( res ) {
          commit('CLEAR_LEAD_DATA');
          dispatch('getAllLeads');
        }
        return res;
      });
  },

  updateProductsForTaskService() {
    return axios({
      method: 'GET',
      url: `${baseUrl}/api/process/sakuraTaskProducts`,
      headers
    })
    .then( res => { return res } )
    .catch(err => console.log('err', err) );
  },

}
