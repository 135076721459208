const tag = document.createElement('script');
const sc  = document.createElement('script');
const VUE_APP_ANALYTICS_ID = process.env.VUE_APP_ANALYTICS_ID;

tag.src = `https://www.googletagmanager.com/gtag/js?id=${ VUE_APP_ANALYTICS_ID }`;
tag.setAttribute('async', true);

sc.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${ VUE_APP_ANALYTICS_ID }');
`;

document.body.append(tag);
document.body.append(sc);
