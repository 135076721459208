/*=========================================================================================
  Description: Doctors Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  doctors                : [],
  query                  : '',
  doctorsWithSchedule    : [],
  doctorsWithoutSchedule : [],
  doctor                 : {
    type_event : [],
  },
  appointment            : {
    name      : '',
    durartion : 30, // mins
    price     : 80000, // COP
    id_doctor : null,
  },
  appointmentTypes       : [],
}
