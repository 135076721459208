/*=========================================================================================
  File Name: moduleUserGetters.js
  Description: User Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  identificationTypes( state ) {
    return state.identificationTypes;
  },

  roles (state) {
    return state.roles;
  },

  stores (state) {
    return state.stores;
  },

  users (state) {
    return state.users;
  },

  user (state) {
    return state.user;
  },
}
