/*=========================================================================================
  File Name: moduleUserMutations.js
  Description: User Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_CLIENTS( state, clients ){
    state.clients = clients;
  },

  /* SET_CLIENT( state, client ){
    state.client = client;
  }, */

  SET_CLIENT( state, client ) {
    state.client = {
      ...state.client,
      ...client
    };
  },

  CLEAR_CLIENT_DATA( state ) {
    state.client = {}
  }
}