/*=========================================================================================
  File Name: moduleRolesPermissionsActions.js
  Description: Roles & Permissions Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import http from '../../http/http-client.js'

export default {
  getAllRoles( {commit} ) {
    return http.get( 'api/roles' )
      .then( res => {
        if( res ) commit( 'SET_ROLES', res.data );
        return res;
      })
  },
}
