/*=========================================================================================
  File Name: moduleNotification.js
  Description: Notification Module
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/


import state from './moduleNotificationState.js';
import mutations from './moduleNotificationMutations.js';
import actions from './moduleNotificationActions.js';
import getters from './moduleNotificationGetters.js';

export default {
	namespaced: true,
    state,
    mutations,
    actions,
    getters
}
