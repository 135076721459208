/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import axios    from 'axios';
import http from '../../http/http-client.js';
const baseUrl = process.env.VUE_APP_BASE_LOAD;

let headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
}
export default {
  getAllRoles( {commit} ) {
    http.get( 'api/roles' )
      .then( res => {
        if( res ) {
          commit('SET_ROLES', res.data);
        }
      });
  },

  getAllStores( {commit} ) {
    http.get( 'api/shops' )
      .then( res => {
        if( res ) {
          commit('SET_STORES', res.data);
        }
      });
  },

  async getClients( {commit} ) {
    const res = await http.get(`api/userClients` );
  
    if( res ) commit('SET_CLIENTS', res.data);
    
    return res;
  },
  async getAllClients( {commit}, payload ) {
    const u = new URLSearchParams(payload).toString();
    const res = await http.get( `api/userClients?${ u }` );
    if( res ) commit( 'SET_CLIENTS', res.data );
    return res;
  },

  async userClients( {dispatch, commit}, payload ) {
    const res = await http.post( 'api/userClients', payload );
    if( res ) {
      dispatch( 'getAllClients' );
      commit('CLEAR_CLIENT_DATA');
    }
    return res;
  },

  getUserById( {commit}, id ) {
    return http.get( `api/users/${id}` )
      .then( res => {
        if( res ) commit( 'SET_USER', res.data[0] );
        return res;
      });
  },

  updateUser( {dispatch, commit}, user ) {
    return http.put( `api/userClientsUD/${user.id}`, user )
      .then( res => {
        if( res ) {
          dispatch( 'getAllClients' );
          commit('CLEAR_CLIENT_DATA');
        }
        return res;
      });
  },

  deleteUser( {dispatch}, userId ) {
    return http.delete( `api/users/${userId}` ).then( res => {
      if( res ) dispatch( 'getAllUsers' );
      return res;
    });
  },

  updateCLientsForTaskService() {
    return axios({
      method: 'GET',
      url: `${baseUrl}/api/process/sakuraUsers`,
      headers
    })
    .then( res => { return res } )
    .catch(err => console.log('err', err) );
  },

  async UserClientList() {
    const res = await http.get(`api/UserClientList` );  
    return res;
  },

  async getAllConsumers( {commit}, payload ) {
    const u = new URLSearchParams(payload).toString();
    const res = await http.get( `api/userConsumers?${ u }` );
    if( res ) commit( 'SET_CLIENTS', res.data );
    return res;
  },

  async userConsumers( {commit}, payload ) {
    const res = await http.post( 'api/userConsumers', payload );
    if( res ) {
      commit('CLEAR_CLIENT_DATA');
    }
    return res;
  },

  async putUserConsumers ( {commit}, payload ) {
    const res = await http.put( `api/userConsumers/${payload.id}`, payload );
    if( res ) {
      commit('CLEAR_CLIENT_DATA');
    }
    return res;
  }
}
