/*=========================================================================================
  Description: Tracking Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import http from '../../http/http-client.js';

import Cookies  from 'js-cookie';
import firebase from 'firebase/app';
import 'firebase/firestore';

const user = Cookies.get('userInfo') ? JSON.parse( Cookies.get('userInfo') ) : null;

export default {

  async getAllOrders( {commit}, payload  ) {
    // firebase.firestore().collection('serviroutes_tracking')
    //   .where( 'app_id', '==', user.company.app_id )
    //   .orderBy('time', 'desc')
    //   .onSnapshot( document => {
    //     let orders = [];
    //     document.docs.forEach( doc => {
    //       orders.push( { ...doc.data(), id: doc.id } );
    //     });
    //     commit( 'SET_ODERS', orders );
    //   }); 
    const u = new URLSearchParams(payload).toString();
    return await http.get( `api/orders?${ u }` )
      .then( res => {
        if( res ) commit( 'SET_ODERS', res.data );
        return res;
      });
  },

  async getOrdersStatistics(_, payload ) {
    const u = new URLSearchParams(payload).toString();
    return await http.get( `api/orderStatistics?${ u }` )
      .then( res => {
        if( res ) return res;
      });
  },

  getAllNewOrders( {commit} ) {
    firebase.firestore().collection('serviroutes_tracking')
      .where( 'app_id', '==', user.company.app_id )
      .where( 'status', '==', parseInt( process.env.VUE_APP_NEW_ORDER ) )
      .orderBy('time', 'desc')
      .onSnapshot( document => {
        let orders = [];
        document.docs.forEach( doc => {
          orders.push( { ...doc.data(), id: doc.id } );
        });
        commit( 'SET_NEW_ODERS', orders );
      });
  },

  getAllPreparingOrders( {commit} ) {
    firebase.firestore().collection('serviroutes_tracking')
      .where( 'app_id', '==', user.company.app_id )
      .where( 'status', '==', parseInt( process.env.VUE_APP_PREPARING_ORDER ) )
      .orderBy('time', 'desc')
      .onSnapshot( document => {
        let orders = [];
        document.docs.forEach( doc => {
          orders.push( { ...doc.data(), id: doc.id, delivery_time: { value: 1, time: 'Days' } } );
        });
        commit( 'SET_PREPARING_ODERS', orders );
      });
  },

  getAllDeliveredOrders( {commit} ) {
    firebase.firestore().collection('serviroutes_tracking')
      .where( 'app_id', '==', user.company.app_id )
      .where( 'status', '==', parseInt( process.env.VUE_APP_TRACKING_ORDER_DISPATCHED ) )
      .orderBy('time', 'desc')
      .onSnapshot( document => {
        let orders = [];
        document.docs.forEach( doc => {
          orders.push( { ...doc.data(), id: doc.id } );
        });
        commit( 'SET_DELIVERED_ODERS', orders );
      });
  },

  acceptOrder( _, orderId ) {
    const payload = {
      status_order: parseInt( process.env.VUE_APP_PREPARING_ORDER ),
      status_tracking: parseInt( process.env.VUE_APP_TRACKING_PREPARING_ORDER ),
    };

    return http.put( `api/tracking/${orderId}`, payload )
      .then( res => res );
  },

  rejectOrder( _, rejectOrderData ) {
    const payload = {
      ...rejectOrderData,
      status_order: parseInt( process.env.VUE_APP_ORDER_REJECTED_BY_STORE ),
      status_tracking: parseInt( process.env.VUE_APP_TRACKING_REJECTED_BY_STORE ),
    };

    return http.put( `api/tracking/${rejectOrderData.orderSelectedId}`, payload )
      .then( res => res );
  },

  getRejectMessages( {commit} ) {
    return http.get( 'api/rejectedResponse' )
      .then( res => {
        if( res ) {
          commit( 'SET_REJECT_MESSAGES', res.data );
        }
        return res;
      });
  },

  dispatchOrder( _, order ) {
    const payload = {
      id              : order.id,
      status_order    : parseInt( process.env.VUE_APP_ORDER_SHIPPED ),
      status_tracking : parseInt( process.env.VUE_APP_TRACKING_ORDER_DISPATCHED ),
      delivery_time   : `${order.delivery_time.value} ${order.delivery_time.time}`,
    };

    return http.put( `api/tracking/${payload.id}`, payload )
      .then( res => res );
  },

  deliverOrder( _, orderId ) {
    const payload = {
      status_order: parseInt( process.env.VUE_APP_ORDER_FINALIZED ),
      status_tracking: parseInt( process.env.VUE_APP_TRACKING_ORDER_DELIVERED ),
    };

    return http.put( `api/tracking/${orderId}`, payload )
      .then( res => res );
  },

  changeNewOrdersToReaded() {
    firebase.firestore().collection('serviroutes_tracking')
      .where( 'app_id', '==', user.company.app_id )
      .where( 'status', '==', parseInt( process.env.VUE_APP_NEW_ORDER ) )
      .where('unread', '==', true)
      .get()
      .then(res => {
        res.forEach( order => {
          order.ref.update({
            unread: false,
          });
        });
      })
      .catch(err => {
        console.log(err.message);
      });
  },

  filterOrders( { commit}, data ) {
    // for( let i=0; i<data.storesIds.length; i+=10 ) {
    //   storesDivided.push( data.storesIds.slice(i, i +10 ) );
    // }

    let ordersRef = firebase.firestore().collection('serviroutes_tracking');
    
    let query = ordersRef.where( 'app_id', '==', user.company.app_id );
    query = query.where( 'status', 'in', [
      parseInt( process.env.VUE_APP_NEW_ORDER ),
      parseInt( process.env.VUE_APP_TRACKING_PREPARING_ORDER ),
      parseInt( process.env.VUE_APP_TRACKING_ORDER_DISPATCHED ),
    ]);
    query = query.orderBy('time', 'desc');
    
    query.onSnapshot( document => {
      let orders = [];
      document.docs.forEach( doc => {
        orders.push({
          ...doc.data(),
          id: doc.id,
          // if delivery time is null set to 1 days
          delivery_time: doc.data().delivery_time ? doc.data().delivery_time : {  value: 1, time: 'Days' },
        });
      });

      // Filter stores where user have relationship
      const ordersFiltered = orders.filter( order => data.storesIds.includes( order.shop.shop_id)  );
      commit( 'SET_FILTERED_ORDERS', ordersFiltered );
    });
  },

  toggleStoreStatus( {dispatch}, storeId ) {
    return http.delete( `api/tracking/${storeId}` ).then( res => {
      if( res )  dispatch('stores/getAllStores', {}, { root: true } );
      return res;
    });
  },

  getOrderByID( {commit}, order_id) {
    return http.get( `api/orders/${ order_id }` )
    .then( res => {
      if( res ) commit( 'SET_ODER', res.data );
      return res;
    });
  },
  async findOrdersHistory( _, payload ) {
    const res = await http.put('api/orders', payload);
    return res;
  }

}
