import axios from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.VUE_APP_BASE_URL;
export default {
  notify: null,
  init(notify) {
    axios.defaults.baseURL = baseURL;
    this.notify = notify;
    // GET user from cookie
    let activeUser = Cookies.get("userInfo");
    // SET TOKEN to authorization header
    if (activeUser) {
      activeUser = JSON.parse(activeUser);
      axios.defaults.headers.common[
        "Authorization"
      ] = `${activeUser.authorization.token_type} ${activeUser.authorization.access_token}`;
    }
    // SET custom headers
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Access-Control-Allow-Methods"] =
      "GET, POST, PATCH, PUT, DELETE, OPTIONS";
    axios.defaults.headers.common["Access-Control-Allow-Headers"] =
      "Origin, Content-Type, X-Auth-Token";
  },

  get(url) {
    return axios
      .get(`/${url}`, { data: {} })
      .then(this.handleResponse)
      .catch((err) => this.handleError(err.response));
  },

  post(url, data) {
    return axios
      .post(`/${url}`, data)
      .then(this.handleResponse)
      .catch((err) => this.handleError(err.response));
  },

  put(url, data) {
    return axios
      .put(`/${url}`, data)
      .then(this.handleResponse)
      .catch((err) => this.handleError(err.response));
  },

  delete(url) {
    return axios
      .delete(`/${url}`, { data: {} })
      .then(this.handleResponse)
      .catch((err) => this.handleError(err.response));
  },

  handleResponse(res) {
    // Google chrome and opera
    if (window.performance.memory) {
      const limit = 1000000000;
      const mem = window.performance.memory;
      const used = mem.usedJSHeapSize;

      if (used > limit) window.location.reload(true);
    }
    // TODO validate memory in firefox, edge, safari

    return res.data;
  },

  handleError(response) {
    
    if (!response) return null;

    switch (response.status) {
      case 401:
        this.notify({
          title:
            "No cuenta con permisos suficientes para realizar esta operación",
          text: `${response.data.message} ${response.data.error}`,
          color: "warning",
          time: 8000,
        });
        // redireccionar a login
        localStorage.clear();
        this.router.push("/auth/login").catch(() => {});

        break;

      case 403:
        this.notify({
          title:
            "No cuenta con permisos suficientes para realizar esta operación",
          text: `${response.data.message} ${response.data.error}`,
          color: "warning",
          time: 8000,
        });
        localStorage.clear();
        this.$router.push("/auth/login").catch(() => {});

        // redireccionar a login
        break;

      case 404:
        this.notify({
          title: "No se encontró la información solicitada",
          text: `${response.data.message} ${response.data.error}`,
          color: "warning",
          time: 8000,
        });
        break;

      case 422:
        this.notify({
          title: "Error de validación",
          text: `${response.data.message} ${response.data.error}`,
          color: "warning",
          time: 8000,
        });
        break;

      case 500:
        this.notify({
          title: "Error de servidor",
          text: `${response.data.message} ${response.data.error}`,
          color: "warning",
          time: 8000,
        });
        break;

      default:
        if (response.data.error === "The sku does not exist") return;

        this.notify({
          title: "Ocurrió un error",
          text: `${response.data.message} ${response.data.error}`,
          color: "warning",
          time: 8000,
        });
        break;
    }

    return null;
  },
};
