/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import http from '../../http/http-client.js';

export default {
  getAllRoles( {commit} ) {
    http.get( 'api/roles' )
      .then( res => {
        if( res ) {
          commit('SET_ROLES', res.data);
        }
      });
  },

  getAllStores( {commit} ) {
    http.get( 'api/shops' )
      .then( res => {
        if( res ) {
          commit('SET_STORES', res.data);
        }
      });
  },

  async getAllUsers( {commit} ) {
    const res = await http.get( 'api/users' );
    if( res ) commit( 'SET_USERS', res.data );
    return res;
  },

  async createUser( {dispatch, commit}, payload ) {
    const res = await http.post( 'api/auth/register/usersAdmin', payload );
    if( res ) {
      dispatch( 'getAllUsers' );
      commit('CLEAR_USER_DATA');
    }
    return res;
  },

  getUserById( {commit}, id ) {
    return http.get( `api/users/${id}` )
      .then( res => {
        if( res ) commit( 'SET_USER', res.data[0] );
        return res;
      });
  },

  updateUser( {dispatch, commit}, user ) {
    return http.put( `api/users/${user.id}`, user )
      .then( res => {
        if( res ) {
          dispatch( 'getAllUsers' );
          commit('CLEAR_USER_DATA');
        }
        return res;
      });
  },

  deleteUser( {dispatch}, userId ) {
    return http.delete( `api/users/${userId}` ).then( res => {
      if( res ) dispatch( 'getAllUsers' );
      return res;
    });
  },
}
