import auth from '../../../auth/authService.js'

export default {
  actionIcon: 'StarIcon',
  highlightColor: 'warning',
  data: [
    // MODULOS APP
    {
      index: 0,
      label: 'Dashboard',
      url: '/app/dashboard',
      labelIcon: 'HomeIcon',
      highlightAction: false,
      show: true,
    },
    {
      index: 1,
      label: 'Mi Perfil',
      url: '/app/profile',
      labelIcon: 'UserIcon',
      highlightAction: false,
      show: auth.isValidModule( 'OWNER, MANAGE_USERS, READ_USERS' ),
    },
    {
      index: 2,
      label: 'Usuarios',
      url: '/app/users/list',
      labelIcon: 'UsersIcon',
      highlightAction: false,
      show: auth.isValidModule( 'OWNER, MANAGE_USERS' ),
    },
    {
      index: 3,
      label: 'Tiendas',
      url: '/app/stores',
      labelIcon: 'ShoppingBagIcon', 
      highlightAction: false,
      show: auth.isValidModule( 'MANAGE_SHOPS, READ_SHOPS' ),
    },
    {
      index: 4,
      label: 'Productos',
      url: '/app/products',
      labelIcon: 'ClipboardIcon',
      highlightAction: false,
      show: auth.isValidModule( 'MANAGE_PRODUCTS, READ_PRODUCTS' ),
    },
    {
      index: 5,
      label: 'Inventario',
      url: '/app/inventory',
      labelIcon: 'ListIcon',
      highlightAction: false,
      show: auth.isValidModule( 'MANAGE_INVENTORIES, READ_INVENTORIES' ),
    },
    {
      index: 6,
      label: 'Promociones',
      url: '/app/promotions',
      labelIcon: 'PercentIcon',
      highlightAction: false,
      show: auth.isValidModule( 'MANAGE_PROMOTION, READ_PROMOTION' ),
    },
    {
      index: 7,
      label: 'Pedidos en Curso',
      url: '/app/orders/in-progress',
      labelIcon: 'ShoppingCartIcon',
      highlightAction: false,
      show: auth.isValidModule( 'MANAGE_ORDERS, READ_ORDERS' ),
    },
    {
      index: 8,
      label: 'Historial de Pedidos',
      url: '/app/orders',
      labelIcon: 'TruckIcon',
      highlightAction: false,
      show: auth.isValidModule( 'MANAGE_ORDERS, READ_ORDERS' ),
    },
    {
      index: 9,
      label: 'Indicadores',
      url: '/app/indicators',
      labelIcon: 'BarChartIcon',
      highlightAction: false,
      show: auth.isValidModule( 'MANAGE_INDICATOR, READ_INDICATOR' ),
    },
    { index: 10,
      label: 'Atención al Cliente',
      url: '/app/customers/support',
      labelIcon: 'HeadphonesIcon',
      highlightAction: false,
      show: auth.isValidModule( 'MANAGE_CALLCENTER, CREATE_CALLCENTER' ),
    },
    { index: 11,
      label: 'Lista de aliados',
      url: '/app/applications/list',
      labelIcon: 'BookIcon',
      highlightAction: false,
      show: auth.isValidModule( 'OWNER' ),
    },
    { index: 12,
      label: 'Crear aliado',
      url: '/app/applications/create',
      labelIcon: 'BookOpenIcon',
      highlightAction: false,
      show: auth.isValidModule( 'OWNER' ),
    },
    
    // {index: 5, label: 'Calendar',                   url: '/apps/calendar/vue-simple-calendar',  labelIcon: 'CalendarIcon',       highlightAction: true},
    // {index: 20, label: 'agGrid Table',              url: '/ui-elements/ag-grid-table',          labelIcon: 'GridIcon',           highlightAction: false},
    // {index: 23, label: 'Breadcrumb Component',      url: '/components/breadcrumb',              labelIcon: 'NavigationIcon',     highlightAction: false},
    // {index: 32, label: 'Loading Component',         url: '/components/loading',                 labelIcon: 'LoaderIcon',         highlightAction: false},
    // {index: 34, label: 'Notifications Component',   url: '/components/notifications',           labelIcon: 'BellIcon',           highlightAction: false},
    // {index: 35, label: 'Pagination Component',      url: '/components/pagination',              labelIcon: 'ChevronsRightIcon',  highlightAction: false, show: true},
    // {index: 36, label: 'Popup Component',           url: '/components/popup',                   labelIcon: 'CopyIcon',           highlightAction: false},
    // {index: 37, label: 'Progress Component',        url: '/components/progress',                labelIcon: 'SlidersIcon',        highlightAction: false},
    // {index: 39, label: 'Slider Component',          url: '/components/slider',                  labelIcon: 'SlidersIcon',        highlightAction: false},
    // {index: 41, label: 'Tooltip Component',         url: '/components/tooltip',                 labelIcon: 'AlertCircleIcon',    highlightAction: false},

    // // FORMS
    // {index: 48, label: 'Number Input Form Element', url: '/forms/form-elements/number-input',   labelIcon: 'TypeIcon',           highlightAction: false},
    // {index: 51, label: 'Form Wizard',               url: '/forms/form-wizard',                  labelIcon: 'GitCommitIcon',      highlightAction: false},
    // {index: 52, label: 'Form Validation',           url: '/forms/form-validation',              labelIcon: 'CheckSquareIcon',    highlightAction: false},

    // // PAGES
    // {index: 54, label: 'Login Page',                url: '/pages/login',                        labelIcon: 'LockIcon',           highlightAction: false},
    // {index: 55, label: 'Register Page',             url: '/pages/register',                     labelIcon: 'UserPlusIcon',       highlightAction: false},
    // {index: 60, label: '404 Page',                  url: '/pages/error-404',                    labelIcon: 'MonitorIcon',        highlightAction: false},
    // {index: 61, label: '500 Page',                  url: '/pages/error-500',                    labelIcon: 'MonitorIcon',        highlightAction: false},
    // {index: 62, label: 'Not Authorized Page',       url: '/pages/not-authorized',               labelIcon: 'XCircleIcon',        highlightAction: false},
    // {index: 63, label: 'Maintenance Page',          url: '/pages/maintenance',                  labelIcon: 'MonitorIcon',        highlightAction: false},
    // {index: 64, label: 'Profile Page',              url: '/pages/profile',                      labelIcon: 'UserIcon',           highlightAction: false},
    // {index: 65, label: 'FAQ Page',                  url: '/pages/faq',                          labelIcon: 'HelpCircleIcon',     highlightAction: false},

    // // EXTENSIONS
    // {index: 74, label: 'Quill Editor',              url: '/extensions/quill-editor',            labelIcon: 'EditIcon',           highlightAction: false},
    // {index: 75, label: 'Drag & Drop',               url: '/extensions/drag-and-drop',           labelIcon: 'CopyIcon',           highlightAction: false},
    // {index: 76, label: 'Datepicker',                url: '/extensions/datepicker',              labelIcon: 'CalendarIcon',       highlightAction: false, show: true},
    // {index: 77, label: 'Datetime Picker',           url: '/extensions/datetime-picker',         labelIcon: 'ClockIcon',          highlightAction: false, show: true},
    // {index: 78, label: 'Access Control',            url: '/extensions/access-control',          labelIcon: 'LockIcon',           highlightAction: false},
    // {index: 81, label: 'Carousel',                  url: '/extensions/carousel',                labelIcon: 'LayersIcon',         highlightAction: false},
    // {index: 83, label: 'Context Menu',              url: '/extensions/context-menu',            labelIcon: 'MoreHorizontalIcon', highlightAction: false},
    // {index: 84, label: 'Star Rating',               url: '/extensions/star-ratings',            labelIcon: 'StarIcon',           highlightAction: false},
    // {index: 85, label: 'Autocomplete',              url: '/extensions/autocomplete',            labelIcon: 'Edit3Icon',          highlightAction: false},
    // {index: 86, label: 'Tree',                      url: '/extensions/tree',                    labelIcon: 'GitPullRequestIcon', highlightAction: false},
    // {index: 68, label: 'Import',                    url: '/import-export/import',               labelIcon: 'FileTextIcon',       highlightAction: false},
    // {index: 68, label: 'Export',                    url: '/import-export/export',               labelIcon: 'ExternalLinkIcon',   highlightAction: false, show: true },
    // {index: 68, label: 'Export Selected',           url: '/import-export/export-selected',      labelIcon: 'ExternalLinkIcon',   highlightAction: false},
  ]
}
