/*=========================================================================================
  Description: Category Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import http from '@/http/http-client.js';
import axios from 'axios';

export default {
  getAllCategories( {commit} ) {
    return http.get('api/category')
      .then(res => {
        if( res ) commit( 'SET_CATEGORIES', res.data );
        return res;
      });
  },

  createCategorie( {dispatch}, category ) {
    return http.post( 'api/category', category )
      .then( res => {
        if( res ) dispatch( 'getAllCategories' );
        return res;
      });
  },

  updateCategorie( {dispatch}, category ) {
    return http.put( `api/category/${category.id}`, category )
      .then( res => {
        if( res ) dispatch( 'getAllCategories' );
        return res;
      });
  },

  deleteCategorie( {dispatch}, category ) {
    return http.delete( `api/category/${category.id}` )
      .then( res => {
        if( res ) dispatch( 'getAllCategories' );
        return res;
      });
  },

  uploadMassiveCategories( _, files=[] ) {
    files.forEach( ( file ) => {
      const formData = new FormData();

      formData.append( "file", file.file );

      axios({
        method: 'POST',
        data: formData,
        url: `${process.env.VUE_APP_BASE_URL}/api/uploadCategory`,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
        onUploadProgress: ( progressEv ) => {
          const progress = Math.round( (progressEv.loaded * 100) / progressEv.total );
          file.progress = progress;
        }
      })
        .then( () => {
          // TODO validar respuesta
        } )
        .catch( ( err ) => {
          file.error = err.response.data.message;
        });
    });
  },

}
