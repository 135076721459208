/*=========================================================================================
  Description: Promotions Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_PROMOS( state, promos ) {
    state.promotions = promos;
  },
  SET_BANNER( state, banner ) {
    state.banner = banner;
  },
  SET_BANNER_TYPES(state, types) {
    state.bannerTypes = types;
  },
  CLEAR_BANNER_DATA( state ) {
    state.banner = {
      id              : null,
      type            : 2,
      start_date_prog : '',
      start_hour      : '',
      end_date_prog   : '',
      end_hour        : '',
      name            : '',
      shops           : [],
      redirect_id     : 0,
      link            : '',
      image           : '',
      banner_category_id : 0
    }
  },
  SET_BANNERS( state, banners ) {
    state.banners = banners;
  },
  SET_COUPONS( state, coupons ) {
    state.coupons = coupons;
  },
  SET_COUPON( state, coupon ) {
    state.coupon = coupon;
  },
  CLEAR_COUPON( state ) {
    state.coupon  = {
      code             : "",
      name             : "",
      text             : "",
      price            : 0,
      start_hour       : "",
      start_date_prog  : "",
      end_hour         : "",
      end_date_prog    : "",
      minimum_purchase : 0, // Not required
      view             : 0, // Not required
      identification   : "", // Not required
      shop_id          : 0, // Not required
      status           : 2, // Not required
    }
  }
}
