class Utils {

    constructor() {}

    groupBy( list=[], keyGetter ) {
        const map = new Map();
        list.forEach( (item) => {
            const key = keyGetter( item );
            const collection = map.get( key );

            if( !collection ) map.set( key, [ item ] );
            else collection.push( item );
        });
        return map;
    }

    dateToStr( date ) {
        const year     = date.getFullYear();
        const month    = date.getMonth() + 1;
        const day      = date.getDate();
        const hours    = date.getHours();
        const minutes  = date.getMinutes();
        const seconds  = date.getSeconds();
        const mseconds = date.getMilliseconds();

        const fullDatetime = `${year}-${ month < 10 ? '0' + month : month }-${ day < 10 ? '0' + day : day } ${ hours < 10 ? '0' + hours : hours }:${ minutes < 10 ? '0' + minutes : minutes }:${ seconds < 10 ? '0' + seconds : seconds }.${ mseconds }`;

        return fullDatetime;
    }

}

export default new Utils();
