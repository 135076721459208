/*=========================================================================================
  File Name: moduleLocationState.js
  Description: Location Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  departments: [],
  cities: [],
  callsigns: [{
    callingCodes : ['57'],
    flag         : require('../../assets/images/flags/es.png'),
  }],
}
