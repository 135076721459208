/*=========================================================================================
  File Name: moduleRolesPermissionsState.js
  Description: Roles & Permissions Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  roles : [],
}
