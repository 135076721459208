import Vue from 'vue'

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	let arr = value.split(" ")
	let capitalized_array = []
	arr.forEach((word) => {
		let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
		capitalized_array.push(capitalized)
	})
	return capitalized_array.join(" ");
});

Vue.filter('title', function (value, replacer="_") {
  if (!value) return ''
  value = value.toString()

  let arr = value.split(replacer)
  let capitalized_array = []
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(" ");
});

Vue.filter('truncate', function(value, limit) {
	return value.substring(0, limit)
});

Vue.filter('tailing', function(value, tail) {
	return value + tail;
});

Vue.filter('time', function(value, is24HrFormat = false) {
	if(value) {
		const date = new Date(Date.parse(value));
		let hours = date.getHours();
		const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
		if(!is24HrFormat) {
			const time = hours > 12 ? 'AM' : 'PM';
			hours = hours % 12 || 12;
			return hours + ':' + min + ' ' + time
		}
		return hours + ':' + min
	}
});

Vue.filter('date', function(value, fullDate = false) {
	value = String(value)
	const date = value.slice(8,10).trim();
	const month = value.slice(4,7).trim();
	const year = value.slice(11,15);

	if(!fullDate) return date + ' ' + month;
	else return date + ' ' + month + ' ' + year;
});

Vue.filter('month', function(val, showYear = true) {
	val = String(val)
	const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
	const monthsToSpanish = {
		'Jan' : 'Enero',
		'Feb' : 'Febrero',
		'Mar' : 'Marzo',
		'Apr' : 'Abril',
		'May' : 'Mayo',
		'Jun' : 'Junio',
		'Jul' : 'Julio',
		'Aug' : 'Agosto',
		'Sep' : 'Septiembre',
		'Oct' : 'Octubre',
		'Nov' : 'Noviembre',
		'Dec' : 'Diciembre',
	};

	const monthSpanish = monthsToSpanish[ regx.exec(val)[1] ];
	
	if(!showYear) return monthSpanish;
	else return monthSpanish + ' ' + regx.exec(val)[2];
});

Vue.filter('csv', function(value) {
	return value.join(', ')
});

Vue.filter('filter_tags', function(value) {
	return value.replace(/<\/?[^>]+(>|$)/g, "")
});

Vue.filter('k_formatter', function(num) {
	return num > 999 ? (num/1000).toFixed(1) + 'k' : num
});

Vue.filter( 'coin', function( value ) {
    const formatter = new Intl.NumberFormat( 'pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
});

Vue.filter( 'fileFormat', function( value ) {
    return value.substr(value.lastIndexOf('.') + 1);
});

Vue.filter( 'timeWithoutSecond', function( val ) {
	return val.slice( 0, -3 );
});

Vue.filter( 'phone', function( val ) {
	const region = val.substring( 0, 3 );
	const number = val.substring( 3, 10 );
	
	return `(${region}) ${number}`
});

Vue.filter( 'cellphone', function( val ) {
	const region = val.substring( 1, 3 );
	const number = val.substring( 3, 13 );
	
	return `(${region}) ${number}`
});

Vue.filter( 'datetime', function( val ) {
	const date = new Date( val * 1000 );
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getUTCDate();
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const seconds = date.getSeconds();
	const datetime = `
		${year}-${ month < 10 ? `0${month}` : month }-${ day < 10 ? `0${day}` : day } ${ hours < 10 ? `0${hours}` : hours }:${ minutes < 10 ? `0${minutes}` : minutes }:${ seconds < 10 ? `0${minutes}` : seconds }
	`;
	return datetime;
});

Vue.filter('fixedFloat', function (val=0, limit=2) {
	return val.toFixed(limit);
});

Vue.filter('sku', function( val='' ) {
	return val.substr(3, val.length);
});
