/*=========================================================================================
  Description: Doctors Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import sitiHttp from '../../http/siti-http-client';

export default {
  async getAllDoctors( {commit} ) {
    const payload = {
      quantity : 100,
      num_page : 1,
      cx       : -74.072092,
      cy       : 4.6482837,
    };

    const res = await sitiHttp.post( 'api/v1/filters/doctors', payload );
    if( res ) commit( 'SET_DOCTORS', res.data.doctors );
    return res;
  },

  async searchDoctorsWithSchedule( {commit}, name ) {
    const payload = {
      name_medical : name,
      schedule     : 1,
      quantity     : 10,
      num_page     : 1,
    }

    const res = await sitiHttp.post( 'api/v1/filterMedical', payload );
    if( res ) {
      if( res.data[0] ) commit('SET_DOCTORS_WITH_SCHEDULE', res.data[0].list_doctors );
    }
    return res;
  },

  async searchDoctorsWithoutSchedule( {commit}, name ) {
    const payload = {
      name_medical : name,
      schedule     : 2,
      quantity     : 10,
      num_page     : 1,
    }

    const res = await sitiHttp.post( 'api/v1/filterMedical', payload );
    if( res ) {
      if( res.data[0] ) commit('SET_DOCTORS_WITHOUT_SCHEDULE', res.data[0].list_doctors );
    }
    return res;
  },

  async getDoctor( {commit}, id ) {
    const res = await sitiHttp.get( `api/v1/UserPerfil/${id}` );
    if( res ) commit( 'SET_DOCTOR', res.data );
    return res;
  },

  async createEvent( _, event ) {
    const res = await sitiHttp.post( 'api/v1/scheduling', event );
    return res
  },

  async getAppointmentTypes( {commit}, doctor_id ) {
    const res = await sitiHttp.get( `api/v1/TypeAppointment/${doctor_id}` );
    if( res ) commit( 'SET_APPOINTMENT_TYPES', res.data );
    return res;
  },

  async cancelDoctorEvent( _, payload ) {
    const res = await sitiHttp.put( 'api/v1/events/scheduler/cancel', payload );
    return res;
  },

  async deleteMassiveScheduler( _, payload ) {
    const res = await sitiHttp.post('api/v1/scheduler/delete/massive', payload);
    return res;
  },

  async saveAppointment( {commit, dispatch}, payload ) {
    const res = await sitiHttp.post( 'api/v1/TypeAppointment', payload );
    if( res ) {
      dispatch('getDoctor', payload.id_doctor);
      commit('CLEAR_APPOINTMENT');
    }
    return res;
  },

  async updateAppointment( _, payload ) {
    const res = await sitiHttp.put( `api/v1/TypeAppointment/${ payload.id }`, payload );
    return res;
  },

  async deleteAppointment( {dispatch}, payload ) {
    const res = await sitiHttp.delete( `api/v1/TypeAppointment/${ payload.id }` );
    if( res ) dispatch('getDoctor', payload.id_doctor);
    return res;
  },
  
}
