/*=========================================================================================
  Description: Applications Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import firebase from 'firebase/app';
import 'firebase/storage';

import http from '../../http/http-client.js'

export default {
  getAllApplications( {commit} ) {
    return http.get('api/applications')
      .then( res => {
        if( res ) commit( 'SET_APPS', res.data );
        return res;
      });
  },

  getApplication( {commit}, app_id ) {
    return http.get( `api/applications/${app_id}` )
      .then( res => {
        if( res ) commit( 'SET_APP', res.data[0] );
        return res;
      });
  },

  getDefaultImage( {commit} ) {
    const storage = firebase.storage();
    let ref = storage.ref('images/allies/build-default.png');

    return ref.getDownloadURL()
      .then( url => {
        commit( 'SET_DEFAULT_IMAGE_URL', url );
        commit( 'SET_APP_IMAGE_URL', url );
        return url;
      })
      .catch( err => {
        console.log(`An error ocurred trying get the default product Image ${err.message}`);
        return null;
      });
  },

  uploadAlliedImage( {commit}, file ) {
    const storage       = firebase.storage();
    const filename      = file.file.name;
    const ext           = filename.substr(filename.lastIndexOf('.') + 1);
    const currTimestamp = new Date().getTime();

    let ref = storage.ref(`images/allies/${currTimestamp}.${ext}`);
    return new Promise( ( resolve, reject ) => {
      let task = ref.put( file.file )

      task.on( 'state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        file.progress = percentage;
      });

      task.then( snapshot => {
        snapshot.ref.getDownloadURL().then( (link) => {
          resolve(link);
          commit( 'SET_APP_IMAGE_URL', link );
        });
      });

      task.catch( (err) => {
        // referenciar y mostrar en consola error de archivo
        console.log(err);
        file.error = 'Ocurrió un error al guardar el archivo. Intente de nuevo más tarde';
        reject(err);
      });
    });

  },

  saveAllied( {dispatch, commit}, payload ) {
    if( payload.id ) {
      return http.put(`api/applications/${payload.id}`, payload )
      .then( res => {
        if( res ) {
          dispatch( 'getAllApplications' );
          commit('CLEAR_APP_DATA');
        }
        return res;
      });

    } else {
      return http.post('api/applications', payload )
        .then( res => {
          if( res ) {
            dispatch( 'getAllApplications' );
            commit('CLEAR_APP_DATA');
          }
          return res;
        });

    }
  },

  deleteApplication( {dispatch}, app_id ) {
    return http.delete( `api/applications/${app_id}` )
      .then( res => {
        if( res ) dispatch( 'getAllApplications' );
        return res;
      });
  },

  getAllStates( {commit} ) {
    return http.get('api/status')
      .then( res => {
        if( res ) commit('SET_STATES', res.data);
        return res;
      });
  }
}
