/*=========================================================================================
  Description: Messages Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import http from '../../http/http-client.js';

export default {
  async create({commit}, message) {
    const res = await http.post('api/messaging', message);
    if( res ) commit('RESET_MESSAGE');
    return res;
  },

  async getAll({commit}) {
    const res = await http.get('api/messaging');
    if( res ) {
      commit('SET_MESSAGES', res.data);
      commit('RESET_MESSAGE');
    }
    return res;
  },
  async get({commit}, id) {
    const res = await http.get('api/messaging/' + id);
    if( res ) commit('SET_MESSAGE', res.data);
    return res;
  },
  async delete({dispatch}, id) {
    const res = await http.get('api/messaging/delete/' + id);
    if( res ) dispatch('getAll');
    return res;
  },
  async deleteMassive({dispatch}) {
    const res = await http.get('api/messaging/delete_massive');
    if( res ) dispatch('getAll');
    return res;
  }
}
