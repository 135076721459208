/*=========================================================================================
  File Name: moduleNotificationGetters.js
  Description: Notification Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  notifications( state ) {
    return state.notifications;
  },
}
