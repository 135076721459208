/*=========================================================================================
  File Name: moduleNotificationActions.js
  Description: Notification Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import Cookies  from 'js-cookie';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// import fb from 'firebase'; // to get timestamp

const user = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;

export default {
  getAllNotifications( {commit} ) {    
    firebase.firestore().collection('serviroutes_notifications')
      .where('uid', '==', user.uid)
      .orderBy('time', 'desc')
      .onSnapshot( document => {
        let notifications = [];
        
        document.docs.forEach( doc => {
          notifications.push( { ...doc.data(), id: doc.id } );
        });

        commit( 'SET_NOTIFICATIONS', notifications );
      });
  },

  getNotification( {commit}, id ) {
    return firebase.firestore().collection('serviroutes_notifications')
      .doc(id)
      .get()
      .then(res => {
        commit( 'SET_NOTIFICATION', res.data() );
        return res.data();

      })
      .catch(err => {
        console.log(err.message);

        return null;
      });
  },

  changeNotificationsToReaded( {dispatch} ) {
    firebase.firestore().collection('serviroutes_notifications')
      .where('uid', '==', user.uid)
      .where('unread', '==', true)
      .get()
      .then(res => {
        res.forEach( ntf => {
          ntf.ref.update({
            unread: false,
          });
        });

        dispatch('getAllNotifications');

      })
      .catch(err => {
        console.log(err.message);
      });
  },

  deleteAllNotifications( {commit} ) {
    firebase.firestore().collection('serviroutes_notifications')
    .where('uid', '==', user.uid)
    .get()
    .then( res => {
      res.forEach( ntf => {
        ntf.ref.delete();
      });

      commit( 'SET_NOTIFICATIONS', [] );
    })
    .catch(err => {
      console.log(err.message);
    });
  },

  setNotification( {dispatch}, notification ){
    const date = new Date();
    const payload = {
      ...notification,
      unread : true,
      uid    : user.uid,
      // time   : fb.database.ServerValue.TIMESTAMP, // Get firebase time
      time   : new Date( date.valueOf() + date.getTimezoneOffset() * 60000 ).getTime(),
    }

    firebase.firestore().collection('serviroutes_notifications')
      .doc()
      .set(payload)
      .then(() => {
        dispatch( 'getAllNotifications' );
      })
      .catch( err => {
        console.log(err.message);
      });

  },

}
