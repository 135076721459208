/*=========================================================================================
  File Name: moduleIndicatorsMutations.js
  Description: Indicators Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_INDICATORS( state, indicators ) {
    state.indicators = indicators;
  },

  SET_QUESION_REPORTS( state, reports ) {
    state["questionReports"] = reports;
  },

  SET_SHOPS_REPORTS( state, reports ) {
    state["shopsReports"] = reports;
  },

  SET_RANGE_DATE_SELECTED( state, rangeDateSelected ) {
    state.rangeDateSelected = rangeDateSelected;
  },
  
}
