/*=========================================================================================
  File Name: moduleLocationMutations.js
  Description: Location Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_DEPARTMENTS( state, departments ) {
    state.departments = departments;
  },

  SET_CITIES( state, cities ) {
    state.cities = cities;
  },

  SET_CALLSIGNS( state, signs ) {
    state.callsigns = signs;
  },
}
