/*=========================================================================================
  Description: Inventories Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {

  SET_PREORDERS( state, preorders ) {
    state.preorders = preorders;
  },

}
