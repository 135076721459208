/*=========================================================================================
  Description: Firebase Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/


export default {
}
