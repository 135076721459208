/*=========================================================================================
  Description: Applications Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  apps : [
    { id: 24, name: 'FARMATODO' },
    { id: 25, name: 'LA REBAJA' },
    { id: 26, name: 'FARMACITY' },
    { id: 27, name: 'FARMACIA MARIA RIOS' },
    { id: 28, name: 'Revofit' },
    { id: 29, name: 'Pharma2.0' },
    { id: 30, name: 'LOCATEL_PRUEBAS' },
  ],
  app  : {
    name           : '',
    description    : '',
    email          : '',
    url            : '',
    url_image      : '',
    companies_id   : 16,
    payment_method : [],
  },
  states : [],
  payment_methods : ["RUNWAY","CASH"],
}
