/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleECommerce from './eCommerce/moduleECommerce.js';
import moduleNotification from './notification/moduleNotification.js';
import moduleCustomers from './customers/moduleCustomers.js';
import moduleIndicators from './indicators/moduleIndicators.js';
import moduleRolesPermissions from './roles-permissions/moduleRolesPermissions.js';
import stores       from './stores/index';
import applications from './applications/index.js';
import doctors      from './doctors/index';
import calendar     from './calendar/index';
import firebase     from './firebase/index';
import products     from './products/index';
import categories   from './categories/index';
import promotions   from './promotions/index.js';
import inventories  from './inventories/index.js';
import preorders  from './preorders/index.js';
import tracking     from './tracking/index.js';
import location     from './location/';
import users        from './users';
import clients        from './clients';
import messages     from './messages';
import auth         from './auth';

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        // todo: moduleTodo,
        // calendar: moduleCalendar,
        // chat: moduleChat,
        // email: moduleEmail,
        eCommerce        : moduleECommerce,
        notification     : moduleNotification,
        customers        : moduleCustomers,
        indicators       : moduleIndicators,
        rolesPermissions : moduleRolesPermissions,
        stores,
        applications,
        doctors,
        calendar,
        firebase,
        products,
        categories,
        promotions,
        inventories,
        preorders,
        tracking,
        location,
        users,
        clients,
        messages,
        auth,
      },
      strict: process.env.NODE_ENV !== 'production'
})
