/*=========================================================================================
  Description: Inventories Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import Cookies  from 'js-cookie';
import firebase from 'firebase/app';
import 'firebase/storage';

import http from '../../http/http-client.js';

const userInfo = Cookies.get('userInfo') ? JSON.parse( Cookies.get('userInfo') ) : null;

export default {

  async getAllInventories( {commit} ) {
    const inventories = await http.get(`api/inventory` );
  
    if( inventories ) commit('SET_INVENTORIES', inventories.data);
    
    return inventories;
  },

  loadMassiveInventory( {dispatch}, files ) {
    let fileErrors = [];

    files.forEach( file => {
      const filename = file.file.name;
      const ext = filename.substr(filename.lastIndexOf('.') + 1);

      const currentTimestamp = new Date().getTime();
      const route = `SAKURA/files/inventory/app_${userInfo.company.app_id}/${currentTimestamp}.${ext}`;
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(route);

      let task = fileRef.put(file.file);

      task.on( 'state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        file.progress = percentage;
      });

      task.then( snapshot => {
        
        snapshot.ref.getDownloadURL().then( (link) => {
          const data = {
            firebaseUrl  : link,
            bucketPath   : `gs://${snapshot.ref.bucket}/${route}`,
            originalFile : filename,
            extension    : ext,
          }

          dispatch( 'setInventoryFileToService', data );

        });

      });

      task.catch( (err) => {
        console.log(err);
        file.loadError = 'Ocurrió un error al guardar el archivo. Intente de nuevo más tarde'

        fileErrors.push(file);
      });

    });
    
    return true;

  },

  setInventoryFileToService( _, data ) {
    const payload = {
      firebase_url  : data.firebaseUrl,
      bucket_path   : data.bucketPath,
      original_file : data.originalFile,
      extension     : data.extension,
    }

    return http.post( 'api/uploadInventory', payload )
      .then( res => {
        return res;
      });
  },

  deleteProduct( {dispatch}, productId ) {
    return http.delete( `api/inventory/${productId}` )
      .then( res => {
        if( res ) dispatch( 'getAllInventories' );
        return res;
      });
  },

  filterInventories( {commit}, payload ) {
    return http.post( 'api/inventoryfind', payload )
      .then( res => {
        if( res ){
          commit( 'SET_INVENTORIES', res.data );
        }

        return res;
      });
  },

  updateInventorie( {dispatch}, inventory ) {
    return http.put( `api/inventory/${inventory.id}`, inventory )
      .then( res => {
        if( res ) dispatch( 'getAllInventories' );
        return res;
      });
  },

  async findInventories( {commit}, payload ) {
    const inventories = await http.post(`api/inventorypaginate`, payload );
    
    if( inventories ) commit('SET_INVENTORIES', inventories.data.results);
    
    return inventories;
  },

  async findPromotions( {commit}, payload ) {
    const inventories = await http.post(`api/inventorypaginatediscount`, payload );
    
    if( inventories ) commit('SET_INVENTORIES', inventories.data.results);
    
    return inventories;
  },

  async deleteAllInventorie( {dispatch} ) {
    const res = await http.delete('api/delete_all_inventory');
    if(res) dispatch('getAllInventories');
    return res;
  },

  async createWithJSON( _, payload ) {
    const res = await http.post(`api/spatial/inventory/uploadByJson`, payload );
    return res;
  },
}
