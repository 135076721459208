/*=========================================================================================
  Description: Promotions Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  promotions  : [],
  banner      : {
    id              : null, 
    type            : 2,
    start_date_prog : '',
    start_hour      : '',
    end_date_prog   : '',
    end_hour        : '',
    name            : '',
    shops           : [],
    redirect_id     : 0,
    link            : '',
    image           : '',
    banner_category_id : 0
  },
  bannerTypes : [],
  banners     : [],
  coupons     : [],
  coupon      : {
    code             : "",
    name             : "",
    text             : "",
    price            : 0,
    start_hour       : "",
    start_date_prog  : "",
    end_hour         : "",
    end_date_prog    : "",
    shops            : [],
    minimum_purchase : 0, // Not required
    view             : 0, // Not required
    identification   : "", // Not required
    shop_id          : 0, // Not required
    status           : 2, // Not required
  }
}
