/*=========================================================================================
  File Name: moduleUserState.js
  Description: User Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  identificationTypes: [
    {id: 1, name: 'CC'},
    {id: 2, name: 'CE'},
    {id: 3, name: 'NIT'},
  ],
  roles: [],
  stores: [
    {id: 0, name: ''},
  ],
  users: [],
  user: {
    name                    : '',
    lastname                : '',
    city                    : "",
    city_id                 : '',
    departmentId            : '',
    address                 : '',
    companies_id            : 0,
    companies_neme          : '',
    email                   : '',
    email2                  : '',
    password                : '',
    password2               : '',
    id                      : 0,
    identification          : '',
    position                : '',
    role_id                 : 2,
    role_name               : '',
    status_id               : 1,
    store                   : [],
    phoneSign               : '+57',
    phoneNumber             : '',
    role                    : 2,
    country_code_phone      : '+57',
    phone                   : '',
    country_code_cell_phone : '+57',
    cell_phone              : '',
    applications_id         : 0,
    stores                  : [],
    send_email              : 1,
  },
  userStatus : [
    { id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' },
  ]
}
