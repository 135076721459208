/*=========================================================================================
  Description: Applications Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_MESSAGES(state, messages) {
    state.messages = messages;
  },

  SET_MESSAGE(state, message) {
    state.message = message;
  },

  RESET_MESSAGE(state) {
    state.message = {
      title  : '',
      body        : '',
      bucket_path : '',
      color       : '#841910',
      sound       : '',
      image       : '',
      type_       : '', // TODO pendiente
      text        : '',
      date_ts     : 0,
      message_data : {
        success : 0,
        failure : 0,
      },
    }
  }

}
