/*=========================================================================================
  Description: Promotions Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import http from '../../http/http-client.js'

export default {

  getAllPromos( {commit} ) {
    return http.get( 'api/promotions' )
      .then( res => {
        if( res ) commit( 'SET_PROMOS', res.data );
        return res;
      });
  },

  filterInventories( {commit}, payload ) {
    return http.post( 'api/inventorydiscount', payload )
      .then( res => {
        if( res ){
          commit( 'SET_INVENTORIES', res.data );
        }

        return res;
      });
  },

  updatePromo( _, promo ) {
    return promo;
  },

  deletePromo( _, promoId ) {
    return promoId;
  },

  getAllBannerTypes( {commit} ) {
    return http.get( 'api/banner_type_shop' )
      .then( res => {
        if( res ) commit( 'SET_BANNER_TYPES', res.data );
        return res;
      });
  },

  async saveBanner({dispatch}, payload) {
    if( payload.id ) return dispatch('updateBanner', payload);
    else return dispatch('createBanner', payload);
      
  },

  createBanner( {commit, dispatch}, payload ) {
    return http.post('api/banners', payload).then( res => {
      if( res ) {
        commit( 'CLEAR_BANNER_DATA' );
        dispatch('getAllBanners');
      }
      return res;
    });
  },

  updateBanner( {commit, dispatch}, payload ) {
    return http.put(`api/banners/${payload.id}`, payload).then( res => {
      if( res ) {
        commit( 'CLEAR_BANNER_DATA' );
        dispatch('getAllBanners');
      }
      return res;
    });
  },

  getAllBanners( {commit} ) {
    return http.get( 'api/banners' )
      .then( res => {
        if( res ) commit( 'SET_BANNERS', res.data.promotions );
        return res;
      });
  },

  getBannerById( {commit}, bannerId=0 ) {
    return http.get( `api/banners/${bannerId}` )
      .then( res => {
        if( res ) commit('SET_BANNER', res.data.promotions[0]);
        
        let conct = [];
        Object.values(res.data).forEach((category) => {
          category.forEach((prod) => {
            conct.push(prod)
          });
        });
        return conct[0];
      });
  },

  deleteBanner( {dispatch}, bannerId=0 ) {
    return http.delete( `api/banner_type_shop/${bannerId}`)
      .then( res => {
        if( res ) dispatch('getAllBanners');
        return res;
      });
  },

  changeBannerStatus( {dispatch}, bannerId=0 ) {
    return http.delete( `api/banners/${bannerId}`)
      .then( res => {
        if( res ) dispatch('getAllBanners');
        return res;
      });
  },

  async saveCoupon( {commit}, payload ) {
    const res = await http.post('api/promotions', payload );
    
    if( res ) commit('CLEAR_COUPON');

    return res;
  },

  async updateCoupon( {commit, dispatch}, payload ) {
    const res = await http.put(`api/promotions/${ payload.id }`, payload );
    
    if( res ) {
      commit('CLEAR_COUPON');
      dispatch('getAllCoupons');
    }

    return res;
  },

  async getAllCoupons( {commit} ) {
    const res = await http.get('api/promotions');
    
    if( res ) commit('SET_COUPONS', res.data);

    return res;
  },

  async getCoupon( {commit}, code ) {
    const res = await http.get(`api/promotions/${ code }`);
    
    if( res ) commit('SET_COUPON', res.data);

    return res;
  },

  async deleteCoupon( {dispatch}, code ) {
    const res = await http.delete(`api/promotions/${ code }`);
    
    if( res ) dispatch('getAllCoupons');

    return res;
  },
}
