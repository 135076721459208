/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import firebase from 'firebase/app';
import 'firebase/storage';

import http from '../http/http-client';
import sitiHttp from '../http/siti-http-client';

const actions = {

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
    updateUserRole({ dispatch }, payload) {
      // Change client side
      payload.aclChangeRole(payload.userRole)

      // Make API call to server for changing role

      // Change userInfo in localStorage and store
      dispatch('updateUserInfo', {userRole: payload.userRole})
    },
    updatePageTitle( {commit}, pageTitle ) {
      commit( 'SET_PAGE_TITLE', pageTitle );
    },

    getFileUrl( {commit}, url ) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child( `${url}`);
        
      return fileRef.getDownloadURL()
        .then( link =>  {
          commit( 'SET_FILE_SAMPLE_URL', link );
          return link;
        })
        .catch( err => {
          console.error(`An error ocurred trying get the file ${ url }: ${ err.message }`);
          return null;
        })
    },

    checkService() {
      return http.get('api/shops') .then( res => res );
    },

    checkSitiService() {
      return sitiHttp.get('api/v1/departament') .then( res => res );
    },
}

export default actions
