/*=========================================================================================
  File Name: moduleProductMutations.js
  Description: Product Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_PRODUCTS( state, products ){
    state.products = products;
  },

  SET_PRODUCT( state, product ) {
    state.product = product;
  },

  SET_FILES( state, files ) {
    state.fileList = files;
  },

  SET_FILE_PROGRESS( state, file ) {
    state.fileList[file.index].progress = file.progress;
    state.fileList[file.index].loadError = file.loadError ? file.loadError : '';
  },

  REMOVE_FILE( state, index ) {
    state.fileList.splice( index, 1 );
  },

  SET_DEFAULT_IMAGE_URL( state, url ) {
    state.defaultImageUrl = url;
  },

  CLEAR_PRODUCT_DATA( state ) {
    state.product = {
      title           : '',
      applications_id : 0,
      category_id     : 0,
      summary         : '',
      state           : 1,
      content         : '',
      med_form        : 1,
      sku             : '',
      image_url       : '',
      status_id       : 1,
      type_product    : 1,
      email_list      : "",
      email_list_arr  : [],
    };
  },

  SET_LEADS( state, leads ) { state.leads = leads; },

  SET_LEAD( state, lead ) { state.lead = lead; },

  CLEAR_LEAD_DATA( state ) {
    state.lead = {
      city           : '',
      closing_date   : null,
      code           : '',
      contact_date   : null,
      contract_price : null,
      email          : '',
      full_name      : '',
      id             : 0,
      identification : '',
      phone          : '',
      send_date      : '',
      state          : null,
      status         : 0,
      user_id        : 0,
    }
  },

}
