/*=========================================================================================
  Description: Category Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  categories( state ) {
    return state.categories;
  },
}
