/*=========================================================================================
  File Name: moduleUserMutations.js
  Description: User Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_ROLES( state, roles ){
    state.roles = roles;
  },

  SET_STORES( state, stores ){
    state.stores = stores;
  },

  SET_USERS( state, users ){
    state.users = users;
  },

  SET_USER( state, user ) {
    state.user = {
      ...state.user,
      ...user
    };
  },

  CLEAR_USER_DATA( state ) {
    state.user = {
      name                    : '',
      lastname                : '',
      city                    : "",
      city_id                 : '',
      departmentId            : '',
      address                 : '',
      companies_id            : 0,
      companies_neme          : '',
      email                   : '',
      email2                  : '',
      password                : '',
      password2               : '',
      id                      : 0,
      identification          : '',
      position                : '',
      role_id                 : 2,
      role_name               : '',
      status_id               : 1,
      store                   : [],
      phoneSign               : '+57',
      phoneNumber             : '',
      role                    : 2,
      country_code_phone      : '+57',
      phone                   : '',
      country_code_cell_phone : '+57',
      cell_phone              : '',
      applications_id         : 0,
      stores                  : [],
    }
  }
}