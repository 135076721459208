/*=========================================================================================
  File Name: moduleLocationActions.js
  Description: Location Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import http from '../../http/http-client.js';
// import axios from '../../http/axios/index.js';

export default {
  getAllDepartments( { commit } ) {
    http.get( 'api/departments' )
      .then( res => {
        if( res ) {
          commit('SET_DEPARTMENTS', res.data );
        }
      });
  },

  getCitiesByDepartment( {commit}, id ) {
    http.get( `api/cities/departamentId/${id}` )
      .then( res => {
        if( res ) {
          commit('SET_CITIES', res.data );
        }
      });
  },

  getAllCities( { commit } ) {
    return http.get( 'api/cities' )
      .then( res => {
        if( res ) {
          commit('SET_CITIES', res.data );
        }
        return res;
      });
  },

  getAllSigns() {
    // return axios.get('https://restcountries.eu/rest/v2/lang/es')
    //   .then( res => {
    //     commit( 'SET_CALLSIGNS', res.data);
    //     return res;
    //   })
    //   .catch( err => {
    //     console.warn('Error con el API restcountries: ', err.response);
    //     return null;
    //   });
    return [];
  },

  getAddresInfo( _, payload ) {
    return http.post( 'api/geo', payload )
      .then( res => {
        if( res ) return res.data;
        return res;
      });
  }
}
