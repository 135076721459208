/*=========================================================================================
  File Name: moduleLocationGetters.js
  Description: Location Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  location( state ) {
    return state.location;
  },

  callsigns( state ) {
    return state.callsigns;
  },
}
