/*=========================================================================================
  File Name: moduleProductGetters.js
  Description: Product Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  products( state ) {
    return state.products;
  },

  fileList( state ) {
    return state.fileList;
  }
}
