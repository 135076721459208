/*=========================================================================================
  Description: Tracking Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import Utils from '../../plugins/utils.js';

export default {
  SET_ODERS( state, orders ) {
    state.orders = orders;
  },

  SET_NEW_ODERS( state, newOrders ) {
    state.newOrders = newOrders;
  },

  SET_PREPARING_ODERS( state, preparingOrders ) {
    state.preparingOrders = preparingOrders;
  },

  SET_DELIVERED_ODERS( state, deliveredOrders ) {
    state.deliveredOrders = deliveredOrders;
  },

  SET_PREPARING_ODER_TIME( state, data ) {
    state.preparingOrders[data.key].delivery_time = { value: parseInt( data.value ), time: data.time };
    state.preparingOrders = state.preparingOrders;
  },

  SET_REJECT_MESSAGES( state, rejectMessages ) {
    state.rejectMessages = rejectMessages;
  },

  SET_FILTERED_ORDERS( state, orders ) {
    const grouped     = Utils.groupBy( orders, order => order.shop.shop_id );
    const groupSetter = new Set(grouped);
    let groupMapped   = [];
    
    for( const item of groupSetter ) {
      groupMapped.push({...item, collapsed: true, panel : ''});
    }
    
    state.filteredStores = groupMapped;
  },

  SET_FILTERED_ORDERS_MAPPED( state, ordersMapped ) {
    state.filteredStores = ordersMapped;
  },
  
}
