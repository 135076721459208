/*=========================================================================================
  Description: Firebase Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import Cookies from "js-cookie";
import firebase from "firebase/app";
import "firebase/storage";

const userInfo = Cookies.get('userInfo') ? JSON.parse( Cookies.get('userInfo') ) : null;

export default {
  uploadFilesToStorage( {dispatch}, files=[] ) {
    files.forEach( file => {
      const destination = file.destination ? file.destination : `files/zones/app_${userInfo.company.app_id}`
      const filename = file.file.name;
      const ext = filename.substr(filename.lastIndexOf('.') + 1);

      const currentTs  = new Date().getTime();
      const route      = `${ destination }/${currentTs}.${ext}`;
      const storageRef = firebase.storage().ref();
      const fileRef    = storageRef.child(route);

      let task = fileRef.put(file.file);

      task.on( 'state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        file.progress = percentage;
      });

      task.then( snapshot => {
        
        snapshot.ref.getDownloadURL().then( (link) => {
          const data = {
            firebase_url  : link,
            bucket_path   : `gs://${snapshot.ref.bucket}/${route}`,
            original_file : filename,
            extension     : ext,
          };
          dispatch( file.action, data, { root: true } );
        });

      });

      task.catch( (err) => {
        console.log(err);
        file.error = `${err.message}. Intente de nuevo más tarde`;
      });

    });
  },

  uploadImage( _, image ) {
    const file = image.file;
    // obtener nombre y extension de archivo
    const filename = file.name;
    const ext = filename.substr(filename.lastIndexOf('.') + 1);
    // crear nombre basado en linea temporal referencia de firestorage
    const currentTimestamp = new Date().getTime();
    const route = `images/app_${ userInfo.company.app_id }/${currentTimestamp}.${ext}`;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(route);
    // tarea de archivo
    return new Promise( ( resolve, reject ) => {
      let task = fileRef.put(file);
      // obtener progreso de subida de archivo
      task.on( 'state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        image.progress = percentage;
      });
      // archivo subido correctamente
      task.then( snapshot => {
        // obtener enlace de descarga
        snapshot.ref.getDownloadURL().then( (link) => {
          resolve(link)
        });

      });
      // error al subir el archivo
      task.catch( (err) => {
        // referenciar y mostrar en consola error de archivo
        console.log(err);
        image.error = 'Ocurrió un error al guardar el archivo. Intente de nuevo más tarde';
        reject( null );
      });
    });
  },

  uploadDocument( _, document ) {
    const file = document.file;
    // obtener nombre y extension de archivo
    const filename = file.name;
    const ext = filename.substr(filename.lastIndexOf('.') + 1);
    // crear nombre basado en linea temporal referencia de firestorage
    const currentTimestamp = new Date().getTime();
    const route = `documents/app_${ userInfo.company.app_id }/${currentTimestamp}.${ext}`;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(route);
    // tarea de archivo
    return new Promise( ( resolve, reject ) => {
      let task = fileRef.put(file);
      // obtener progreso de subida de archivo
      task.on( 'state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        document.progress = percentage;
      });
      // archivo subido correctamente
      task.then( snapshot => {
        // return file path
        resolve(snapshot.ref.fullPath);

      });
      // error al subir el archivo
      task.catch( (err) => {
        // referenciar y mostrar en consola error de archivo
        console.log(err);
        document.error = 'Ocurrió un error al guardar el archivo. Intente de nuevo más tarde';
        reject( null );
      });
    });
  },
}
