/*=========================================================================================
  File Name: moduleCustomersState.js
  Description: Customers Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {

  customerServiceLines: []

}
