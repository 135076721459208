/*=========================================================================================
  Description: Applications Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_APPS( state, apps ) {
    state.apps = apps;
  },
  SET_APP( state, app ) {
    state.app = app;
  },
  SET_APP_INDEX( state, obj ) {
    state.app[obj.index] = obj;
  },
  SET_DEFAULT_IMAGE_URL( state, url ) {
    state.default_image_url = url;
  },
  SET_APP_IMAGE_URL( state, url ) {
    state.app = {
      ...state.app,
      url_image : url,
    }
  },
  SET_STATES( state, states ) {
    state.states = states;
  },
  CLEAR_APP_DATA( state ) {
    state.app = {
      name         : '',
      description  : '',
      email        : '',
      url          : '',
      url_image    : '',
      companies_id : 16,
    }
  }
}
