/*=========================================================================================
  File Name: moduleNotificationState.js
  Description: Notification Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  notifications: [],
  notification: {},
}
