/*=========================================================================================
  Description: Tracking Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  orders          : [],
  newOrders       : [],
  preparingOrders : [],
  deliveredOrders : [],
  rejectMessages  : [],
  filteredStores  : [],
}
