/*=========================================================================================
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import jwt    from "../../http/requests/auth/jwt/index.js"
import http   from '../../http/http-client.js';
import router from '@/router'

import Cookies  from 'js-cookie'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails    : payload.userDetails,
      notify         : payload.notify,
      closeAnimation : payload.closeAnimation,
      loginSiti      : payload.loginSiti,
    }
    // If remember_me is enabled change firebase Persistence
    if ( !payload.rememberMe ) {
      Cookies.remove( 'email' );
      // Change firebase Persistence
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        // If success try to login
        .then(function() {
          dispatch('login', newPayload)
        })
        // If error notify
        .catch(function() {
          // Close animation if passed as payload
          if(payload.closeAnimation) payload.closeAnimation()
            payload.notify({
                time: 2500,
                title: 'Error',
                text: 'El correo electrónico o la contraseña son incorrectos',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
          })
    } else {
      Cookies.set( 'email', payload.userDetails.email );
      // Try to login
      dispatch('login', newPayload);
    }
  },

  login({ commit, state, dispatch }, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if(payload.closeAnimation) payload.closeAnimation()

      payload.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
      });
      
      return false
    }

    // Try to sigin
    firebase.auth().signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then((result) => {
        // Set FLAG username update required for updating username
        let isUsernameUpdateRequired = false

        if( payload.loginSiti ) {          
          payload['uid'] = result.user.uid;
          payload['photoURL'] = result.user.photoURL;

          dispatch('loginJWT', payload)
            .then( () => { if(payload.closeAnimation) payload.closeAnimation() } )
            .catch( () => {
              if(payload.closeAnimation) payload.closeAnimation()
                              
              payload.notify({
                time: 2500,
                title: 'Error',
                text: 'El correo electrónico o la contraseña son incorrectos',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            });
            return;
          }
          
          // if username is provided and updateUsername FLAG is true
          // set local username update FLAG to true
          // try to update username
          if(payload.updateUsername && payload.userDetails.username) {
            isUsernameUpdateRequired = true

            dispatch('updateUsername', {
              user: result.user,
              username: payload.userDetails.username,
              notify: payload.notify,
              isReloadRequired: true
            });
          }
          // Close animation if passed as payload
          if(payload.closeAnimation) payload.closeAnimation();

          // if username update is not required
          // just reload the page to get fresh data
          // set new user data in localstorage
          if(!isUsernameUpdateRequired) {
            router.push(router.currentRoute.query.to || '/')
            commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
          }
        }, () => {
          // Close animation if passed as payload
          if(payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: 'El correo electrónico o la contraseña son incorrectos',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        });
  },

  // Google Login
  loginWithGoogle({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
      });
      return false;
    }
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      });
  },

  // Facebook Login
  loginWithFacebook({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      });
      return false;
    }
    const provider = new firebase.auth.FacebookAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      });
  },

  // Twitter Login
  loginWithTwitter({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      });
      return false;
    }
    const provider = new firebase.auth.TwitterAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      });
  },

  // Github Login
  loginWithGithub({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      });
      return false;
    }
    const provider = new firebase.auth.GithubAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      });
  },

  registerUser({dispatch}, payload) {
    // create user using firebase
    firebase.auth().createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then(() => {
        payload.notify({
          title: 'Account Created',
          text: 'You are successfully registered!',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        });

        const newPayload = {
          userDetails: payload.userDetails,
          notify: payload.notify,
          updateUsername: true
        }
        dispatch('login', newPayload)
      }, (error) => {
        payload.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      });
  },

  updateUsername({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.username
      }).then(() => {
        // If username update is success
        // update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0])
        newUserData.displayName = payload.username
        commit('UPDATE_USER_INFO', newUserData, {root: true})
        // If reload is required to get fresh data after update
          // Reload current page
        if(payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || '/')
        }
      }).catch((err) => {
        payload.notify({
          time: 8800,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      });
  },

  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve,reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password, payload.uid)
        .then(response => {
          // If there's user data in response
          if( response.data ) {
            // Navigate User to homepage
            // router.push(router.currentRoute.query.to || '/')  DISABLED  to return access token
            // Set accessToken
            Cookies.set( "accessToken", response.data.authorization.access_token );
            // Update user details
            commit(
              'UPDATE_USER_INFO', {
                ...response.data,
                ...payload, // Merge data with firebase,
                userDetails: null, // Clean auth data
              },  
              {root: true}
            );
            // Set bearer token in axios
            commit( "SET_BEARER", response.data.auth_grants.access_token );
            // Navigate User to homepage
            window.location.href = router.currentRoute.query.to || '/';
            
            resolve(response);

          } else {
            reject({message: "Wrong Email or Password"})
          }
        })
        .catch( () => {
          reject({message: `error` })
        });
      });
  },

  registerUserJWT({ commit }, payload) {
    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve,reject) => {
      // Check confirm password
      if(password !== confirmPassword) {
        reject({message: "Password doesn't match. Please try again."})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')
          // Update data in localStorage
          Cookies.set("accessToken", response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
      })
  },

  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    });
  },

  changePassword( _, password ) {
    const user = firebase.auth().currentUser;

    return user.updatePassword( password )
      .then(() => {
          return { 'ok': 'password changed' };
      })
      .catch( (err) => {
        return { 'err': err.message };
      });
  },

  logout( ) {
    let queryEncoded = '';

    http.get( 'api/logout' )
      .then( res => {
        if( res ) {
          console.warn('Sesión cerrada de servicio');
        }
        // if user is logged in via firebase
        const firebaseCurrentUser = firebase.auth().currentUser

        if (firebaseCurrentUser) {
          firebase.auth().signOut().then(() => console.log('sesión cerrada de firebase') );
        }
        // If JWT token
        if(Cookies.get("accessToken")) {
          Cookies.remove("accessToken")
        }
        // Change role on logout. Same value as initialRole of acj.js
        // this.$acl.change('admin');
        Cookies.remove('userInfo')
        // This is just for demo Purpose. If user clicks on logout -> redirect
        window.location.href = `/auth/login?${queryEncoded}`;
      });
  },

  recoveryFbPwd( _, email) {
    const actionCodeSettings = {
      url : process.env.VUE_APP_MAIN_ADDRESS,
    };

    return firebase.auth().sendPasswordResetEmail( email, actionCodeSettings )
      .then( () => {
        return true;
      })
      .catch( (err) => {
        console.error( err.message );
        return true;
      });
  },
}
