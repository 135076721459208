/*=========================================================================================
  Description: Stores Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  stores     : [],
  store      : {
    name             : '',
    code             : '',
    address          : '',
    city             : '',
    influence        : null,
    cx               : -58.1843,
    cy               : -15.6248,
    coords           : '-15.6248,-58.1843',
    delivery_price   : null,
    phone            : '',
    list_type_shops  : [],
    city_dane        : '',
    minimum_purchase : null,
  },
  categories : [],
}
