/*=========================================================================================
  File Name: moduleRolesPermissionsMutations.js
  Description: Roles & Permissions Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_ROLES( state, roles ) {
    state.roles = roles;
  }
}
