/*=========================================================================================
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {

  // Calendar Labels
  eventLabels: [
    { text: 'Disponible' ,value : 'disponible', color: 'primary' },
    { text: 'Personal', value: 'personal', color: 'warning'},
    { text: 'Asignada', value: 'asignada', color: 'info'},
  ],

  // Simple Calendar State
  events: [
    // { 
    //     id: 1,
    //     title: 'Cita1',
    //     startDate: new Date(new Date() - 1000 * 60 * 60 * 24 * 3),
    //     endDate: new Date(new Date() - 1000 * 60 * 60 * 24 * 2),
    //     url: '',
    //     classes: 'event-primary',
    //     label: 'business'
    // },
  ]
}
