/*=========================================================================================
  File Name: moduleRolesPermissionsGetters.js
  Description: Roles & Permissions Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
}
