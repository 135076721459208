/*=========================================================================================
  File Name: moduleNotificationMutations.js
  Description: Notification Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_NOTIFICATIONS( state, notifications ){
    state.notifications = notifications;
  },

  SET_NOTIFICATION( state, notification ){
    state.notification = notification;
  },
}
