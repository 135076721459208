/*=========================================================================================
  File Name: moduleInventoriesState.js
  Description: Inventories Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  indicators        : [],
  questionReports   : [],
  shopsReports      : [],
  dateRanges        : [
    { id: 1, value : 1,  name : 'Hoy' },
    { id: 2, value : 2,  name : 'Desde ayer' },
    { id: 3, value : 7,  name : 'Hace 7 días' },
    { id: 4, value : 15, name : 'Hace 15 días' },
    { id: 5, value : 30, name : 'Hace un mes' },
    { id: 6, value : 60, name : 'Hace dos meses' },
  ],
  rangeDateSelected : 5,
}
