import axios   from 'axios';
import Cookies from 'js-cookie';

const baseUrl = process.env.VUE_APP_SITIDOCTOR_URL;
let headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
}

export default {
  notify: null,

  init( notify ) {
    this.notify = notify;
    let activeUser = Cookies.get('userInfo');

    if (activeUser) {
      activeUser = JSON.parse( activeUser );
      axios.defaults.headers.common['Authorization'] = `${activeUser.authorization.token_type} ${activeUser.authorization.access_token}`;
    }

    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';
  },

  get( url ) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${url}`,
      headers,
    })
    .then(res => res.data )
    .catch(err => {
        return this.handleError(err.response);
    });
  },

  post( url, data ) {
    // TODO Validar errores de auth
    return axios({
      method: 'POST',
      data,
      url: `${baseUrl}/${url}`,
      headers
    })
    .then(res => res.data )
    .catch(err => {
      return this.handleError(err.response);
    });
  },

  put( url, data ) {
    return axios({
      method: 'PUT',
      data,
      url: `${baseUrl}/${url}`,
      headers
    })
    .then(res => res.data )
    .catch(err => {
      return this.handleError(err.response);
    });
  },

  delete( url ) {
    return axios({
      method: 'DELETE',
      url: `${baseUrl}/${url}`,
      headers
    })
    .then(res => res.data )
    .catch(err => {
      return this.handleError(err.response);
    });
  },

  handleError( response ) {
    if( !response ) return null;

    switch( response.status ) {
      case 401:
        this.notify({
          title : 'No cuenta con permisos suficientes para realizar esta operación',
          text  : `${response.data.message} ${response.data.error}`,
          color : 'warning',
          time  : 8000,
        });
        localStorage.clear()
        this.$router.push('/auth/login').catch(() => {})
    
        // redireccionar a login
        break;

      case 403:
        this.notify({
          title : 'No cuenta con permisos suficientes para realizar esta operación',
          text  : `${response.data.message} ${response.data.error}`,
          color : 'warning',
          time  : 8000,
        });
        // redireccionar a login
        break;

      case 404:
        this.notify({
          title : 'No se encontró la información solicitada',
          text  : `${response.data.message} ${response.data.error}`,
          color : 'warning',
          time  : 8000,
        });
        break;

      case 422:
        this.notify({
          title : 'Error de validación',
          text  : `${response.data.message} ${response.data.error}`,
          color : 'warning',
          time  : 8000,
        });
        break;

      case 500:
        this.notify({
          title : 'Error de servidor',
          text  : `${response.data.message} ${response.data.error}`,
          color : 'warning',
          time  : 8000,
        });
        break;
      
      case 723:
        this.notify({
          title : 'No cuenta con permisos suficientes para realizar esta operación',
          text  : `${response.data.message} ${response.data.error}`,
          color : 'warning',
          time  : 8000,
        });
        localStorage.clear()
        this.$router.push('/auth/login').catch(() => {})
    
        // redireccionar a login
        break;

      

      default:
        if( response.data.error === 'The sku does not exist')
          return;

        this.notify({
          title : 'Ocurrió un error',
          text  : `${response.data.message} ${response.data.error}`,
          color : 'warning',
          time  : 8000,
        });
        break;

    }
    
    return null;
  }

}