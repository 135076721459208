/*=========================================================================================
  File Name: moduleCustomersActions.js
  Description: Customers Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import http from '../../http/http-client.js';

export default {

  getAllCustomers() {
    console.log('hla');
  },

  deleteCustomerSupportLine( {dispatch}, lineId ) {
    return http.delete( `api/callcenter/${lineId}` )
      .then( res => {
        if( res ) {
          dispatch( 'getAllCustomerSupportLines' );
        }

        return res;
      });
  },

  getAllCustomerSupportLines( {commit} ) {
    return http.get( 'api/callcenter' )
      .then( res => {
        if( res ) {
          commit( 'SET_CUSTOMER_SERVICE_LINES', res.data);
        }
        return res;
      });
  },

  createCustomerSupportLine( {dispatch}, line ) {
    return http.post( 'api/callcenter', line )
      .then( res => {
        if( res ) {
          dispatch( 'getAllCustomerSupportLines' );
        }

        return res;
      });
  },

  updateCustomerSupportLine( {dispatch}, line ) {
    return http.put( `api/callcenter/${line.id}`, line )
      .then( res => {
        if( res ) {
          dispatch( 'getAllCustomerSupportLines' );
        }

        return res;
      });
  },

}
