// NOTE
// Please use your own firebase details below. For more details visit: https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html


import firebase from 'firebase/app';
import 'firebase/messaging';

// Initialize Firebase
var config = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

firebase.initializeApp(config);

navigator.serviceWorker.register('/firebase-messaging-sw.js',
    { scope : "firebase-cloud-messaging-push-scope"}
)
    .then((registration) => {
        const messaging = firebase.messaging();
        messaging.useServiceWorker(registration);

    }).catch((err) => {
        console.log('Firebase messaging service worker registration failed', err);
    });
