/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
    path => router path
    name => router name
    component(lazy loading) => component to load
    meta : {
      rule => which user can have access (ACL)
      breadcrumb => Add breadcrumb to specific page
      pageTitle => Display title besides breadcrumb
    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import auth from "@/auth/authService";

import firebase from "firebase/app";
import "firebase/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "/",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "",
          redirect: "/app",
        },
        {
          path: "app",
          // name: 'app',
          component: () => import("./views/app/Index"),
          meta: {
            rule: "admin",
            authRequired: true,
          },
          children: [
            // =============================================================================
            // App child Routes
            // =============================================================================
            {
              path: "",
              redirect: "/app/dashboard",
            },
            // dashboard
            {
              path: "dashboard",
              name: "app-dashboard",
              component: () => import("./views/app/dashboard/Index"),
              meta: {
                // rule: 'admin',
                analytics: "dashboard",
                authRequired: true,
                title: "Inicio",
              },
            },
            // notificaciones
            {
              path: "notifications/:id",
              name: "app-notifications",
              component: () => import("./views/app/notifications/Index"),
              meta: {
                rule: "admin",
                authRequired: true,
              },
            },
            // perfil
            {
              path: "profile",
              name: "app-profile",
              component: () => import("./views/app/profile/Index.vue"),
              meta: {
                //rule: "admin",
                authRequired: true,
                title: "Mi Perfil"
              },
            },
            {
              path: "profile",
              name: "app-profile",
              component: () => import("./views/app/profile/Index.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
              },
            },
            // usuarios
            {
              path: "users",
              name: "app-users",
              component: () => import("./views/app/users/Index.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
                title: "Usuarios",
                perms: "MANAGE_USERS, OWNER",
              },
              children: [
                {
                  path: "",
                  redirect: "list",
                },
                {
                  path: "list",
                  name: "app-users-list",
                  component: () => import("./views/app/users/List.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Usuarios",
                    perms: "MANAGE_USERS, OWNER",
                  },
                },
                {
                  path: "new",
                  name: "app-users-new",
                  component: () => import("./views/app/users/New.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Usuarios",
                    perms: "MANAGE_USERS, OWNER",
                  },
                },
              ],
            },
            // clientes
            {
              path: "clients",
              name: "app-clients",
              component: () => import("./views/app/clients/Index.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
                title: "Clientes",
                perms: "MANAGE_USERS, OWNER",
              },
              children: [
                {
                  path: "",
                  redirect: "list",
                },
                {
                  path: "list",
                  name: "app-clients-list",
                  component: () => import("./views/app/clients/List.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Clientes",
                    perms: "MANAGE_USERS, OWNER",
                  },
                },
                {
                  path: "new",
                  name: "app-clients-new",
                  component: () => import("./views/app/clients/New.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Clientes",
                    perms: "MANAGE_USERS, OWNER",
                  },
                },
              ],
            },
            // consumers
            {
              path: "consumers",
              name: "app-consumers",
              component: () => import("./views/app/consumers/Index.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
                title: "Consumers",
                perms: "MANAGE_USERS, OWNER",
              },
              children: [
                {
                  path: "",
                  redirect: "list",
                },
                {
                  path: "list",
                  name: "app-consumers-list",
                  component: () => import("./views/app/consumers/List.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Consumers",
                    perms: "MANAGE_USERS, OWNER",
                  },
                },
                {
                  path: "new",
                  name: "app-consumers-new",
                  component: () => import("./views/app/consumers/New.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Consumers",
                    perms: "MANAGE_USERS, OWNER",
                  },
                },
                {
                  path: "edit/:id",
                  name: "app-consumers-edit",
                  component: () => import("./views/app/consumers/Edit.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Consumers",
                    perms: "MANAGE_USERS, OWNER",
                  },
                },
              ],
            },
            // Inventario
            /* {
              path: "inventory",
              name: "app-inventory",
              component: () => import("./views/app/inventory/Index.vue"),
              children: [
                {
                  path: "",
                  redirect: "list",
                },
                {
                  path: "list",
                  name: "app-inventory-list",
                  component: () => import("./views/app/inventory/List.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Inventario",
                    perms: "MANAGE_INVENTORIES, READ_INVENTORIES",
                  },
                },
                {
                  path: "load",
                  name: "app-inventory-load",
                  component: () => import("./views/app/inventory/Load.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Inventario",
                    perms: "MANAGE_INVENTORIES, READ_INVENTORIES",
                  },
                },
              ],
            }, */
            // Preorders
            {
              path: "preorders",
              name: "app-preorders",
              component: () => import("./views/app/preorders/Index.vue"),
              children: [
                {
                  path: "",
                  redirect: "list",
                },
                {
                  path: "list",
                  name: "app-preorders-list",
                  component: () => import("./views/app/preorders/List.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "PreOrders",
                    perms: "MANAGE_INVENTORIES, READ_INVENTORIES",
                  },
                },
                {
                  path: "load",
                  name: "app-preorders-load",
                  component: () => import("./views/app/preorders/Load.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "PreOrders",
                    perms: "MANAGE_INVENTORIES, READ_INVENTORIES",
                  },
                },
              ],
            },
            // productos
            {
              path: "products",
              // name: 'app-products',
              component: () => import("./views/app/products/Index.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
              },
              children: [
                {
                  path: "",
                  name: "app-products-index",
                  component: () => import("./views/app/products/CardList.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Productos",
                    perms: "MANAGE_PRODUCTS, READ_PRODUCTS",
                  },
                },
                {
                  path: "new",
                  name: "app-products-new",
                  component: () =>
                    import("./views/app/products/NewProducts.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Agregar Producto",
                    perms: "MANAGE_PRODUCTS, READ_PRODUCTS",
                  },
                },
                /* {
                  path : 'leads',
                  name : 'app-products-leads',
                  component : () => import('./views/app/products/leads/Index.vue'),
                  meta: {
                    rule: 'admin',
                    authRequired: true,
                    perms: 'MANAGE_PRODUCTS, READ_PRODUCTS',
                    title : 'Productos lead'
                  },
                }, */
                // categorias
                {
                  path: "categories",
                  name: "app-product-categories",
                  component: () =>
                    import("./views/app/products/categories/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Agregar Producto",
                    perms: "MANAGE_PRODUCTS, READ_PRODUCTS",
                  },
                  children: [
                    {
                      path: "",
                      redirect: "/app/products/categories/load",
                    },
                    {
                      path: "load",
                      name: "app-product-categories-load",
                      component: () =>
                        import("./views/app/products/categories/Load.vue"),
                      meta: {
                        rule: "admin",
                        authRequired: true,
                        title: "Agregar Producto",
                        perms: "MANAGE_PRODUCTS, READ_PRODUCTS",
                      },
                    },
                  ],
                },
              ],
            },
            // tiendas
            {
              path: "stores",
              // name: 'app-stores',
              component: () => import("./views/app/stores/Index.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
              },
              children: [
                {
                  path: "",
                  name: "app-stores-index",
                  component: () => import("./views/app/stores/StoresList.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Tiendas",
                    perms: "MANAGE_SHOPS, READ_SHOPS",
                  },
                },
                // new
                {
                  path: "new",
                  name: "app-stores-new",
                  component: () => import("./views/app/stores/new/Index.vue"),
                  children: [
                    {
                      path: "",
                      name: "app-stores-new-selection",
                      component: () => import("./views/app/stores/new/New.vue"),
                      meta: {
                        rule: "admin",
                        authRequired: true,
                        title: "Agregar Tienda",
                        perms: "MANAGE_SHOPS, READ_SHOPS",
                      },
                    },
                    {
                      path: "form",
                      name: "app-stores-new-form",
                      component: () =>
                        import("./views/app/stores/new/Form.vue"),
                      meta: {
                        rule: "admin",
                        authRequired: true,
                        perms: "MANAGE_SHOPS, READ_SHOPS",
                      },
                    },
                    {
                      path: "massive",
                      name: "app-stores-new-massive",
                      component: () =>
                        import("./views/app/stores/new/Massive.vue"),
                      meta: {
                        rule: "admin",
                        authRequired: true,
                        perms: "MANAGE_SHOPS, READ_SHOPS",
                      },
                    },
                  ],
                },
                // uploads
                {
                  path: "uploads",
                  name: "app-stores-upload",
                  component: () =>
                    import("./views/app/stores/upload/Index.vue"),
                  children: [
                    {
                      path: "zones",
                      name: "app-stores-upload-zones",
                      component: () =>
                        import("./views/app/stores/upload/UploadZones.vue"),
                      meta: {
                        rule: "admin",
                        authRequired: true,
                        title: "Agregar Tienda",
                        perms: "MANAGE_SHOPS, READ_SHOPS",
                      },
                    },
                  ],
                },
              ],
            },
            // orders
            {
              path: "orders",
              // name: 'app-orders',
              component: () => import("./views/app/orders/Index.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
              },
              children: [
                {
                  path: "",
                  name: "app-orders-index",
                  component: () =>
                    import("./views/app/orders/OrdersHistoryList.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Historial de Pedidos",
                    perms: "MANAGE_ORDERS, READ_ORDERS",
                  },
                },
                {
                  path: "in-progress",
                  name: "app-orders-in-progress",
                  component: () =>
                    import("./views/app/orders/OrdersInProgress.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Pedidos en Progreso",
                    perms: "MANAGE_ORDERS, READ_ORDERS",
                  },
                },
              ],
            },
            // clientes
            {
              path: "customers",
              // name: 'app-customers',
              component: () => import("./views/app/customers/Index.vue"),
              /* meta: {
                rule: 'admin',
                authRequired: true,
              },
              children: [
                {
                  path: '',
                  redirect: '/app/customers/support'
                },
                {
                  path: 'support',
                  // name: 'app-customers-support',
                  component: () => import('./views/app/customers/support/Index.vue'),
                  meta: {
                    rule: 'admin',
                    authRequired: true,
                  },
                  children: [
                    {
                      path: '',
                      name: 'app-customers-support-index',
                      component: () => import('./views/app/customers/support/CustomerSupportList.vue'),
                      meta: {
                        rule: 'admin',
                        authRequired: true,
                        title: 'Atención al Cliente',
                        perms: 'MANAGE_CALLCENTER, CREATE_CALLCENTER',
                      }
                    },
                    {
                      path: 'new',
                      name: 'app-customers-support-new',
                      component: () => import('./views/app/customers/support/CustomerSupportNew.vue'),
                      meta: {
                        rule: 'admin',
                        authRequired: true,
                        title: 'Agregar Nueva Linea de Atención al Cliente',
                        perms: 'MANAGE_CALLCENTER, CREATE_CALLCENTER',
                      }
                    },
                  ]
                },
              ] */
            },
            // indicadores
            /* {
              path: 'indicators',
              name: 'app-indicators',
              component: () => import('./views/app/indicators/Index.vue'),
              meta: {
                rule: "admin",
                authRequired: true,
                title: "Indicadores",
                perms: "MANAGE_INDICATOR, READ_INDICATOR",
              },
            }, */
            // promociones
            {
              path: "promotions",
              name: "app-promotions",
              component: () => import("./views/app/promotions/Index.vue"),
              children: [
                {
                  path: "",
                  name: "app-promotions-list",
                  component: () =>
                    import("./views/app/promotions/list/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Promociones",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                {
                  path: "add",
                  name: "app-promotions-add",
                  component: () =>
                    import("./views/app/promotions/add/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Crear una promoción",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                {
                  path: "edit/:id",
                  name: "app-promotions-edit",
                  component: () =>
                    import("./views/app/promotions/edit/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Editar una promoción",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                {
                  path: "detail/:id",
                  name: "app-promotions-detail",
                  component: () =>
                    import("./views/app/promotions/detail/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Detalle de una promociónß",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                {
                  path: "oldlist",
                  name: "app-promotions-old-list",
                  component: () =>
                    import("./views/app/promotions/oldlist/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Promociones",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                // PROMOTION RULES
                {
                  path: "rules",
                  name: "app-promotions-rules",
                  component: () =>
                    import("./views/app/promotions/rules/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Lista de reglas",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                {
                  path: "rules/add",
                  name: "app-promotions-rules-add",
                  component: () =>
                    import("./views/app/promotions/rules/add/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Crear nueva regla",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                {
                  path: "rules/edit/:id",
                  name: "app-promotions-rules-edit",
                  component: () =>
                    import("./views/app/promotions/rules/edit/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Editar nueva regla",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                {
                  path: "rules/detail/:id",
                  name: "app-promotions-rules-detail",
                  component: () =>
                    import("./views/app/promotions/rules/detail/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Detalles una regla",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                },
                // BANNERS
                {
                  path: "banners",
                  name: "app-promotions-banners",
                  component: () =>
                    import("./views/app/promotions/banners/Index.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Lista de banners",
                    perms: "MANAGE_PROMOTION, READ_PROMOTION",
                  },
                  children: [
                    {
                      path: "",
                      name: "app-promotions-banners-list",
                      component: () =>
                        import("./views/app/promotions/banners/List.vue"),
                      meta: {
                        rule: "admin",
                        authRequired: true,
                        title: "Crear nuevo banner",
                        perms: "MANAGE_PROMOTION, READ_PROMOTION",
                      },
                    },
                    {
                      path: "new",
                      name: "app-promotions-banners-new",
                      component: () =>
                        import("./views/app/promotions/banners/New.vue"),
                      meta: {
                        rule: "admin",
                        authRequired: true,
                        title: "Crear nuevo banner",
                        perms: "MANAGE_PROMOTION, READ_PROMOTION",
                      },
                    },
                  ],
                },
              ],
            },
            // aliados
            {
              path: "applications",
              // name: 'app-applications',
              component: () => import("./views/app/applications/Index.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
                title: "Aliados",
              },
              children: [
                {
                  path: "",
                  redirect: "/app/applications/list",
                },
                {
                  path: "list",
                  name: "app-applications-list",
                  component: () => import("./views/app/applications/List.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Aliados",
                    perms: "OWNER",
                  },
                },
                {
                  path: "create",
                  name: "app-applications-create",
                  component: () =>
                    import("./views/app/applications/Create.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Aliados",
                    perms: "OWNER",
                  },
                },
              ],
            },
            // medicos
            /* {
              path      : 'doctors',
              name      : 'app-doctors',
              component : () => import("./views/app/doctors/Index.vue"),
              children  : [
                {
                  path     : '',
                  redirect : '/app/doctors/find',
                },
                {
                  path : 'find',
                  name : 'app-doctors-find',
                  component : () => import("./views/app/doctors/Find.vue"),
                  meta: {
                    rule: 'admin',
                    authRequired: true,
                    title: 'Lista de médicos',
                    perms: 'OWNER',
                  },
                },
                {
                  path : 'profile',
                  name: 'app-doctors-profile',
                  component: () => import("./views/app/doctors/profile/Index.vue"),
                  children: [
                    {
                      path: '',
                      redirect: '/app/doctors/find'
                    },
                    {
                      path: ':id',
                      name: 'app-doctors-profile-resume',
                      component: () => import("./views/app/doctors/profile/Resume.vue"),
                      meta: {
                        rule: 'admin',
                        authRequired: true,
                        title: 'Perfil médico',
                        perms: 'OWNER',
                      },
                    },
                  ]
                },
                {
                  path : 'appointments/:id',
                  name: 'app-doctors-appointments',
                  component: () => import("./views/app/doctors/profile/Appointments.vue"),
                  meta: {
                    rule: 'admin',
                    authRequired: true,
                    title: 'Citas disponibles',
                    perms: 'OWNER',
                  },
                },
                {
                  path: 'calendar/:id',
                  name: 'app-doctors-calendar',
                  component: () => import("./views/app/doctors/calendar/Index.vue"),
                  children : [
                    {
                      path: '',
                      name: 'app-doctors-calendar-list',
                      component: () => import("./views/app/doctors/calendar/Calendar.vue"),
                      meta: {
                        rule: 'admin',
                        authRequired: true,
                        title: 'Agenda médica',
                        perms: 'OWNER',
                      },
                    },
                    {
                      path: 'delete-massive',
                      name: 'app-doctors-calendar-delete-massive',
                      component: () => import("./views/app/doctors/calendar/DeleteMassive.vue"),
                      meta: {
                        rule: 'admin',
                        authRequired: true,
                        title: 'Eliminación masiva de agenda médica',
                        perms: 'OWNER',
                      },
                    },
                  ]
                },
              ]
            }, */
            // coupons
            {
              path: "coupons",
              name: "app-coupons",
              component: () => import("./views/app/coupons/Index.vue"),
              children: [
                {
                  path: "",
                  redirect: "list",
                },
                {
                  path: "list",
                  name: "app-coupons-list",
                  component: () => import("./views/app/coupons/List.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Lista de cupones",
                    perms: "OWNER",
                  },
                },
                {
                  path: "new",
                  name: "app-coupons-new",
                  component: () => import("./views/app/coupons/New.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Lista de cupones",
                    perms: "OWNER",
                  },
                },
              ],
            },
            // push
            {
              path: "push",
              name: "app-push",
              component: () => import("./views/app/push/Index.vue"),
              children: [
                {
                  path: "",
                  redirect: "list",
                },
                {
                  path: "list",
                  name: "app-push-list",
                  component: () => import("./views/app/push/List.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Lista de notificaciones",
                    perms: "OWNER, MANAGE_USERS",
                  },
                },
                {
                  path: "new",
                  name: "app-push-new",
                  component: () => import("./views/app/push/New.vue"),
                  meta: {
                    rule: "admin",
                    authRequired: true,
                    title: "Crear notificación",
                    perms: "OWNER, MANAGE_USERS",
                  },
                },
              ],
            },
            // logout
            {
              path: "/app/logout",
              name: "app-logout",
              component: () => import("./views/app/Logout.vue"),
              meta: {
                rule: "admin",
                authRequired: true,
              },
            },
          ],
        },
        // =============================================================================
        // Application Routes
        // =============================================================================
        {
          path: "/apps/calendar/vue-simple-calendar",
          name: "calendar-simple-calendar",
          component: () => import("./views/apps/calendar/SimpleCalendar.vue"),
          meta: {
            rule: "editor",
          },
        },
        // =============================================================================
        // UI ELEMENTS
        // =============================================================================
        {
          path: "/ui-elements/ag-grid-table",
          name: "ag-grid-table",
          component: () =>
            import("./views/ui-elements/ag-grid-table/AgGridTable.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "agGrid Table", active: true },
            ],
            pageTitle: "agGrid Table",
            rule: "editor",
          },
        },
        // =============================================================================
        // COMPONENT ROUTES
        // =============================================================================
        {
          path: "/components/breadcrumb",
          name: "component-breadcrumb",
          component: () =>
            import("@/views/components/vuesax/breadcrumb/Breadcrumb.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Breadcrumb", active: true },
            ],
            pageTitle: "Breadcrumb",
            rule: "editor",
          },
        },
        {
          path: "/components/loading",
          name: "component-loading",
          component: () =>
            import("@/views/components/vuesax/loading/Loading.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Loading", active: true },
            ],
            pageTitle: "Loading",
            rule: "editor",
          },
        },
        {
          path: "/components/pagination",
          name: "component-pagination",
          component: () =>
            import("@/views/components/vuesax/pagination/Pagination.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Pagination", active: true },
            ],
            pageTitle: "Pagination",
            rule: "editor",
          },
        },
        {
          path: "/components/popup",
          name: "component-popup",
          component: () => import("@/views/components/vuesax/popup/Popup.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Popup", active: true },
            ],
            pageTitle: "Popup",
            rule: "editor",
          },
        },
        {
          path: "/components/progress",
          name: "component-progress",
          component: () =>
            import("@/views/components/vuesax/progress/Progress.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Progress", active: true },
            ],
            pageTitle: "Progress",
            rule: "editor",
          },
        },
        {
          path: "/components/slider",
          name: "component-slider",
          component: () =>
            import("@/views/components/vuesax/slider/Slider.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Slider", active: true },
            ],
            pageTitle: "Slider",
            rule: "editor",
          },
        },
        {
          path: "/components/tooltip",
          name: "component-tooltip",
          component: () =>
            import("@/views/components/vuesax/tooltip/Tooltip.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Components" },
              { title: "Tooltip", active: true },
            ],
            pageTitle: "Tooltip",
            rule: "editor",
          },
        },
        // =============================================================================
        // FORM ELEMENTS
        // =============================================================================
        {
          path: "/forms/form-elements/number-input",
          name: "form-element-number-input",
          component: () =>
            import("./views/forms/form-elements/number-input/NumberInput.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Form Elements" },
              { title: "Number Input", active: true },
            ],
            pageTitle: "Number Input",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-wizard",
          name: "extra-component-form-wizard",
          component: () => import("@/views/forms/form-wizard/FormWizard.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Form Wizard", active: true },
            ],
            pageTitle: "Form Wizard",
            rule: "editor",
          },
        },
        {
          path: "/forms/form-validation",
          name: "extra-component-form-validation",
          component: () =>
            import("@/views/forms/form-validation/FormValidation.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Form Validation", active: true },
            ],
            pageTitle: "Form Validation",
            rule: "editor",
          },
        },
        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: "/pages/profile",
          name: "page-profile",
          component: () => import("@/views/pages/Profile.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Profile",
            rule: "editor",
          },
        },
        {
          path: "/pages/faq",
          name: "page-faq",
          component: () => import("@/views/pages/Faq.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "FAQ", active: true },
            ],
            pageTitle: "FAQ",
            rule: "editor",
          },
        },
        // =============================================================================
        // EXTENSIONS
        // =============================================================================
        {
          path: "/extensions/quill-editor",
          name: "extra-component-quill-editor",
          component: () =>
            import(
              "@/views/components/extra-components/quill-editor/QuillEditor.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Quill Editor", active: true },
            ],
            pageTitle: "Quill Editor",
            rule: "editor",
          },
        },
        {
          path: "/extensions/drag-and-drop",
          name: "extra-component-drag-and-drop",
          component: () =>
            import(
              "@/views/components/extra-components/drag-and-drop/DragAndDrop.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Drag & Drop", active: true },
            ],
            pageTitle: "Drag & Drop",
            rule: "editor",
          },
        },
        {
          path: "/extensions/datepicker",
          name: "extra-component-datepicker",
          component: () =>
            import(
              "@/views/components/extra-components/datepicker/Datepicker.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Datepicker", active: true },
            ],
            pageTitle: "Datepicker",
            rule: "editor",
          },
        },
        {
          path: "/extensions/datetime-picker",
          name: "extra-component-datetime-picker",
          component: () =>
            import(
              "@/views/components/extra-components/datetime-picker/DatetimePicker.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extra Components" },
              { title: "Datetime Picker", active: true },
            ],
            pageTitle: "Datetime Picker",
            rule: "editor",
          },
        },
        {
          path: "/extensions/access-control",
          name: "extra-component-access-control",
          component: () =>
            import(
              "@/views/components/extra-components/access-control/AccessControl.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Access Control", active: true },
            ],
            pageTitle: "Access Control",
            rule: "editor",
          },
        },
        {
          path: "/extensions/carousel",
          name: "extra-component-carousel",
          component: () =>
            import("@/views/components/extra-components/carousel/Carousel.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Carousel", active: true },
            ],
            pageTitle: "Carousel",
            rule: "editor",
          },
        },
        {
          path: "/extensions/context-menu",
          name: "extra-component-context-menu",
          component: () =>
            import(
              "@/views/components/extra-components/context-menu/ContextMenu.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Context Menu", active: true },
            ],
            pageTitle: "Context Menu",
            rule: "editor",
          },
        },
        {
          path: "/extensions/star-ratings",
          name: "extra-component-star-ratings",
          component: () =>
            import(
              "@/views/components/extra-components/star-ratings/StarRatings.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Star Ratings", active: true },
            ],
            pageTitle: "Star Ratings",
            rule: "editor",
          },
        },
        {
          path: "/extensions/autocomplete",
          name: "extra-component-autocomplete",
          component: () =>
            import(
              "@/views/components/extra-components/autocomplete/Autocomplete.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Autocomplete", active: true },
            ],
            pageTitle: "Autocomplete",
            rule: "editor",
          },
        },
        {
          path: "/extensions/tree",
          name: "extra-component-tree",
          component: () =>
            import("@/views/components/extra-components/tree/Tree.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Tree", active: true },
            ],
            pageTitle: "Tree",
            rule: "editor",
          },
        },
        {
          path: "/import-export/import",
          name: "import-excel",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/Import.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Import", active: true },
            ],
            pageTitle: "Import Excel",
            rule: "editor",
          },
        },
        {
          path: "/import-export/export",
          name: "export-excel",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/Export.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Export", active: true },
            ],
            pageTitle: "Export Excel",
            rule: "editor",
          },
        },
        {
          path: "/import-export/export-selected",
          name: "export-excel-selected",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/ExportSelected.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Export Selected", active: true },
            ],
            pageTitle: "Export Excel",
            rule: "editor",
          },
        },
      ],
    },

    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/register",
          name: "page-register",
          component: () => import("@/views/pages/register/Register.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/comingsoon",
          name: "page-coming-soon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "editor",
          },
        },
        // =============================================================================
        // Auth Routes
        // =============================================================================
        {
          path: "/auth/login",
          name: "page-auth",
          component: () => import("@/views/pages/login/Login.vue"), // TODO implementar auth folder
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) appLoading.style.display = "none";
});

router.beforeEach((to, from, next) => {
  // get and set document title
  const comingSoonPage = to.meta.comingSoon ? true : false;
  const title = to.meta.title
    ? `${to.meta.title} - Sakura Admin`
    : "Sakura Admin";
  document.title = title;

  firebase.auth().onAuthStateChanged(() => {
    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser;

    // If auth required. If login fails redirect to login page
    if (to.meta.authRequired) {
      if (!auth.isAuthenticated() || !firebaseCurrentUser)
        router.push({ path: "/auth/login" });
    }

    if (to.meta.perms) {
      if (!auth.isValidModule(to.meta.perms))
        router.push({
          path: "/app/logout",
          query: {
            err: "You do not have sufficient permissions to enter the module",
          },
        });
    }

    if (comingSoonPage) {
      // redirect to coming Soon page if production mode
      if (process.env.VUE_APP_MODE === "production")
        return next("/pages/comingsoon");
      return next();
    }

    //console.clear();
    return next();
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });
  });
});

export default router;
