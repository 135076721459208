/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList";
import themeConfig            from "@/../themeConfig.js";
import colors                 from "@/../themeConfig.js";

import Cookies from 'js-cookie';

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
const userDefaults = {
  uid         : "",          // From Auth
  displayName : "user", // From Auth
  about       : "",
  photoURL    : "", // require("@/assets/images/portrait/small/avatar-s-11.png"), // From Auth
  status      : "online",
  userRole    : "admin"
}

const userInfo = Cookies.get('userInfo') ? JSON.parse( Cookies.get('userInfo') ) : null;
const userInfoLocalStorage = userInfo ? userInfo : {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
  let userInfo = {}

  // Update property in user
  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key] ?  userInfoLocalStorage[key] : userDefaults[key]
  })

  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach((key) => {
    if(userInfo[key] == undefined && userInfoLocalStorage[key] != null) userInfo[key] = userInfoLocalStorage[key]
  })

  return userInfo
}

// layout title
const pageTitle = {
  title: '',
  subtitle: '',
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////
const reduceButton = localStorage.getItem('reduceButton') &&
  localStorage.getItem('reduceButton') === 'true' ?
  true :
  false;

const state = {
    AppActiveUser           : getUserInfo(),
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    // reduce button menu now is getting from local storage
    // reduceButton            : themeConfig.sidebarCollapsed,
    reduceButton            : reduceButton,
    verticalNavMenuWidth    : localStorage.getItem('verticalNavMenuWidth') || "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    theme                   : themeConfig.theme || "light",
    themePrimaryColor       : colors.primary,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth             : null,
    pageTitle,
    fileSampleURL           : '',
}

export default state
