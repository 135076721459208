/*=========================================================================================
  Description: Applications Module State
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  messages : [],
  message  : {
    title        : '',
    body         : '',
    bucket_path  : '',
    color        : '#841910',
    sound        : '',
    image        : '',
    type_        : '', // TODO pendiente
    text         : '',
    date_ts      : 0,
    message_data : {
      success : 0,
      failure : 0,
    },
  },
}
