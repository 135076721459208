/*=========================================================================================
  Description: Stores Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import http from '@/http/http-client.js';

import Cookies from 'js-cookie';
import firebase from 'firebase/app';
import 'firebase/storage';

const user = Cookies.get('userInfo') ? JSON.parse( Cookies.get('userInfo') ) : null;

export default {
  getAllStores( {commit} ) {
    return http.get( 'api/shops' )
      .then( res => {
        if( res )  commit( 'SET_STORES', res.data );
        return res;
      });
  },

  getStoreByCode() {
    // commit( 'SET_STORE', {} );
    return false;
  },

  createStore( {dispatch, commit}, payload ) {
    return http.post( 'api/shops', payload )
      .then( res => {
        if( res ){
          dispatch( 'getAllStores' );
          commit('CLEAR_STORE_DATA');
        }
        return res;
      });
  },

  uploadStore( {dispatch, commit}, store ) {
    return http.put( `api/shops/${store.id}`, store )
      .then( res => {
        if( res ) {
          commit('CLEAR_STORE_DATA');
          dispatch( 'getAllStores' );
        }
        return res;
      })
  },

  deleteStore( {dispatch}, id ) {
    return http.delete( `api/shops/${id}` )
      .then( res => {
        if( res ) {
          dispatch( 'getAllStores' );
        } 
        return null;
      });
  },

  filterStores( {commit}, payload ) {
    return http.post( 'api/shopsFilter', payload )
      .then( res => {
        if( res ) {
          commit( 'SET_STORES', res.data );
        }

        return res;
      });
  },

  getAllStoreCategories( {commit} ) {
    return http.get( 'api/shopsType' )
      .then( res => {
        if( res ) commit( 'SET_STORE_CATEGORIES', res.data );
        return res;
      });
  },

  getStoreById( {commit}, id ) {
    return http.get( `api/shops/${id}` )
      .then( res => {
        if( res )  commit( 'SET_STORE', res.data[0] );
        return res;
      });
  },

  loadMassiveStores( {dispatch}, files ) {
    files.forEach( file => {
      const filename      = file.file.name;
      const ext           = filename.substr( filename.lastIndexOf('.') + 1 );
      const currTimestamp = new Date().getTime();
      const route         = `SAKURA/files/shops/app_${user.company.app_id}/${currTimestamp}.${ext}`;
      const storageRef    = firebase.storage().ref();
      const fileRef       = storageRef.child( route );

      let task = fileRef.put( file.file );

      task.on( 'state_changed', (snapshot) => {
        let percentage = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100;
        file.progress = percentage;
      });

      task.then( snapshot => {
        snapshot.ref.getDownloadURL().then( () => {
          const data = {
            bucket_path   : route,
            original_name : filename,
          };

          dispatch( 'setStoreFileToService', data);
        });
      });

      task.catch( (err) => {
        console.log(err);
        file.error = `Ocurrió un error al cargar el archivo ${filename}: ${err.message}`;
      });

    });
  },

  setStoreFileToService( _, data ) {
    return http.post( 'api/uploadShopsUrl', data )
      .then( res => res );
  },

  uploadZones( _, data ) {
    return http.post( 'api/zone', data ).then( res => res );
  },

}
