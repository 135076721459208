/*=========================================================================================
  Description: Doctors Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla aliado
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/

export default {
  SET_DOCTORS( state, doctors ) {
    state['doctors'] = doctors;
  },
  SET_DOCTOR( state, doctor ) {
    state['doctor'] = {
      ...state['doctor'],
      ...doctor,
    };
  },
  SET_QUERY( state, query ) {
    state['query'] = query;
  },
  SET_DOCTORS_WITH_SCHEDULE( state, doctors ) {
    state['doctorsWithSchedule'] = doctors;
  },
  SET_DOCTORS_WITHOUT_SCHEDULE( state, doctors ) {
    state['doctorsWithoutSchedule'] = doctors;
  },
  SET_APPOINTMENT_TYPES( state, apTypes ) {
    state['appointmentTypes'] = apTypes;
  },
  SET_APPOINTMENT( state, ap ) { state['appointment'] = ap; },
  CLEAR_APPOINTMENT( state ) {
    state['appointment'] = {
      name      : '',
      durartion : 30, // mins
      price     : 80000, // COP
      id_doctor : null,
    }
  },
}
